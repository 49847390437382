import React, { Component } from 'react';
import styles from './forget.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ApiForgetPassword } from '../../Api/ApiAuth';
import { toast, ToastContainer } from 'react-toastify';

const Forgot = () => {
    const [mobile,setMobile] = useState("");
    const ForgetPassword = () => {
        const forgetPasswordData = {
                Captcha : "test",
                Mobile : mobile
        }
        ApiForgetPassword(forgetPasswordData,(IsOk,response)=>{
            if(IsOk){
                switch (response.statusCode) {
                    case 401:
                        toast.error('ُکاربری با این شماره موبایل یافت نشد' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 400:
                        toast.error('لطفا همه فیلد ها را پر کنید' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 201:
                        toast.success('کلمه عبور جدید برای شما ارسال شد' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    default:
                        break;
                }
            }else{
                console.log(response);
            }
        });
    }
    return (
        <div>
        <div className={`${styles.container}`}>
                    <h3>بازیابی رمز عبور </h3>
                    <p>
شماره موبایل خود را در زیر وارد کنید و ما یک رمز عبور جدید برای شما ارسال خواهیم کرد.</p>
                    <div>
                    <span><FontAwesomeIcon icon={faEnvelope} /></span>
                    <input  onChange={(e) => setMobile(e.target.value)} type="text" placeholder="شماره موبایل" name="mobile"/>
                </div>
                <button onClick={ForgetPassword} className={`${styles.btn}`}>ارسال</button>
        </div>
        <ToastContainer />
        </div>
    );
};

export default Forgot;