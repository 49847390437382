import React, { useContext } from 'react';
import styles from './Baner.module.css'
// import BanerImage from '../images/banner.jpg'
import 'bootstrap/dist/css/bootstrap.css'
import { useEffect } from 'react';
import { ApiSlider } from '../Api/ApiCommon';
import { useState } from 'react';
import Slide from './Sliders/Slide';
import { PageLoaderContext } from '../Context/PageLoaderContext';
import Pageloading from './Pageloading';
 


const Baner = () => {
  const PageLoader = useContext(PageLoaderContext);

  const [Sliders,setSlider] = useState([]);

  useEffect(()=>{
    let pageLoaderCounter = 0;
    pageLoaderCounter = PageLoader.CounterPageLoader;
    pageLoaderCounter += 1
    ApiSlider((IsOk,response) =>{
        if(IsOk)
        {
            setSlider(response); 
            pageLoaderCounter -= 1;
            PageLoader.setCounterPageLoader(pageLoaderCounter);
        }
        else{
            console.log(response);
        }
    });
  },[])
  

    return (
        <div className={styles.container}>
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
  {/* <div class="carousel-item active">
      <img src={SliderOrigin + Sliders[0].imageName} class="d-block w-100" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="..." class="d-block w-100" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="..." class="d-block w-100" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div> */}

            {
              Sliders.length ? Sliders.map((slider, index) => (
                <Slide key={index} slider={slider}/> )) : 
                <Pageloading />
             } 
      
 
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
            
        </div>
        
    );
};

export default Baner;