import React,{useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey , faMobile} from '@fortawesome/free-solid-svg-icons'
// import { ApiLogin , TestApi } from '../../Api/ApiAuth'
import styles from './login.module.css'
import { UserContext } from '../../Context/UserInfo';
import { ApiLogin } from '../../Api/ApiAuth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { OrderContext } from '../../Context/OrderProvider';
const Login = () =>{
    const navigate = useNavigate(); 
    const UserInfo = useContext(UserContext);
    const OrderConfig = useContext(OrderContext);
    const [phonenumber,setPhoneNumber] = useState("");
    const onChangePhoneNumber = (event) => 
    {
        setPhoneNumber(event.target.value);
    }
    const [password,setPassword] = useState("");
    const onChangePassword = (event) => 
    {
        setPassword(event.target.value);
    }
    // const [checkbox,setCheckbox] = useState(false);
    // const onChangeCheckbox = (event) => 
    // {
    //     setCheckbox(event.target.value);
    // }

  
    
    const Submit = () => 
    {
        const data = 
        {
            Mobile : phonenumber,
            Password : password,
            RememberMe : false,
            Captcha : "Default"
        }

       

        ApiLogin(data,(IsOk,response) => 
        {
            if(IsOk)
            {
                switch (response.statusCode) {
                    case 404:
                        toast.error('حساب کاربری با این شماره موبایل یافت نشد' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 401:
                        toast.error('ُحساب کاربری شما فعال نمی باشد' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 400:
                        toast.error('رمز عبور اشتباه می باشد' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 201:

                        UserInfo.setUserToken(response.token);
                        localStorage.setItem('userToken', response.token);
                        UserInfo.setPhoneNumber(phonenumber);
                        localStorage.setItem('userPhoneNumber',phonenumber);
                        UserInfo.setIsLogin(true);
                        localStorage.setItem('IsLogin',true);
                        toast.success('شما با موفقیت وارد حساب کارابری خود شدید ' , {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                        });
                        OrderConfig.ReloadApiProductOrder();
                        setTimeout(function() {
                        navigate("/");
                        }, 3000);
                        break;
                    default:
                        break;
                }
            }
            else{
               console.log(response);
            }
        });

        console.log(UserInfo.UserToken);
        console.log(localStorage.getItem('userPhoneNumber'));
        
    }

        return (
            <div>
 <div className={styles.container}>
                <h3>ورود به حساب کاربری</h3>
                <div>
                    <span><FontAwesomeIcon icon={faMobile} /></span>
                    <input onChange={onChangePhoneNumber} onSelect={onChangePhoneNumber} placeholder="شماره همراه" name="email"/>
                </div>
                <div>
                    <span><FontAwesomeIcon icon={faKey} /></span>
                    <input onChange={onChangePassword} onSelect={onChangePassword}  type="password" placeholder="رمز عبور" name="password"/>
                </div>
                <button className={`${styles.btn}`} onClick={Submit}>ورود</button>
            </div>
            <ToastContainer />
            </div>
           
        );
    
}
export default Login; 






















// constructor(){
//     super();
//     this.state={
//         inputvalue:"",
//         phonenumber:"",
//         passworde:"",
//         rememberMe: true
//     }
// }
// clickHandler =() => {
//     this.setState({
    
        
//     })
//     console.log(this.state.inputvalue)
// }
// MobilechangeHandler =(event)=>{
//     this.setState({
//         inputvalue:event.target.value,
//     })
// }

// PasswordChangeHandler = (event) => {
//     this.setState({
//         passworde : event.target.value
//     })
// }

// LoginSubmit = () => {
//     var LoginData = {
//         captcha : "test",
//         mobile: this.state.inputvalue,
//         password : "1234",
//         rememberMe : true
//     }

    
//     ApiLogin(LoginData , (IsOk , data) => {
//         if(IsOk){
//             localStorage.setItem('userToken', data.token);
         
//         }else{
//             console.log(data)
//         }
//     });
// }

