import React, {  useState } from 'react';
import Login from './Login'
import Signin from './Signin';
import styles from './auth.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock} from '@fortawesome/free-solid-svg-icons';
import Forgot from './Forgot';
import 'bootstrap/dist/css/bootstrap.css'
// import pic from '../images/m.jpg'
// import Login from './Login';
// import Signin from './Signin';
const Regester = () => {
    // constructor(){
    //     super();
    //     this.state={
    //         islog:1,
    //         issignin:0,
    //         forgot:0
    //     }
    // }
  

    const [islogin,setIsLogin] = useState(true);
    const [issignin,setIsSignin] = useState(false);
    const [forgot,setForgot] = useState(false);


    const loginHandler =()=>{
        setIsLogin(true);
        setIsSignin(false);
        setForgot(false);
    }
    const signinHandler = ()=>{
        setIsLogin(false);
        setIsSignin(true);
        setForgot(false);
    }
    const forgotHondler= () =>{
        setIsLogin(false);
        setIsSignin(false);
        setForgot(true);
    }
        return(
            <div className={`${styles.container} `}>
                <div className={`${styles.sidbar}`}> 
                    <div className={islogin&&styles.active} onClick={loginHandler}>
                        <span><FontAwesomeIcon icon={faUserCircle} /></span>
                        <able>ورود</able>
                    </div>
                    <div className={issignin&&styles.active} onClick={signinHandler}>
                    <span><FontAwesomeIcon icon={faUserCircle} /></span>
                        <lable>ثبت نام</lable>
                    </div>
                    <div className={forgot&&styles.active} onClick={forgotHondler}>
                    <span><FontAwesomeIcon icon={faLock} /></span>
                        <lable>رمز خود را فراموش کردم</lable>
                    </div>
                </div>
                    {islogin ? <Login/> : "" }
                    {issignin ? <Signin/> : ""}  
                    {forgot ? <Forgot/> : ""} 
             

            </div>
        )
            if(this.state.islog){
                return (<Login/>)
            }
            else if(this.state.issignin){
                return (<Signin/>)
            }
}


export default Regester;




