import React, { useEffect, useState } from 'react';
import styles from './dashbord.module.css';
import { faPenSquare, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiDeleteAddress, ApiEditAddress, ApiStatesAndCity } from '../../Api/ApiAddress';
import { toast } from 'react-toastify';
import { ApiUserAddressList } from '../../Api/ApiUser';
const AddressCard = ({address,setUserAddressList}) => {
    const [States,setStates] = useState([]);
    const [Cities,setCities] = useState([]);
    const [StateId,setStateId] = useState(1);
    const [CityId,setCityId] = useState(1);
    const [FirstName,setFirstName] = useState(""); 
    const [LastName,setLastName] = useState("");
    const [Mobile,setMobile] = useState("");
    const [Phone,setPhone] = useState("");
    const [PostalCode,setPostalCode] = useState("");
    const [AddressText,setAddressText] = useState("");
    useEffect(()=>{
        ApiStatesAndCity(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                console.log(response[0].cities);
                setStates(response);
                setCities(response[0].cities);
                setStateId(response[0].id);
                setCityId(response[0].cities[0].id)
            }
            else{
                console.log(response);
            }
        });

     
    },[])

    const onChangeStateCreateHandeler = (event) => {
        setStateId(event.target.value);
        for (let i = 0; i < States.length; i++) {
            if(States[i].id == event.target.value){
                setCities(States[i].cities);
            }
        }
        
    }
    const onChangeCityCreateHandeler = (event) => {
        setCityId(event.target.value);
    }
    const onChangeFirstNameCreateHandeler = (event) => {
        setFirstName(event.target.value);
    }
    const onChangeLastNameCreateHandeler = (event) => {
        setLastName(event.target.value);
    }
    const onChangeMobileCreateHandeler = (event) => {
        setMobile(event.target.value);
    }
    const onChangePhoneHandeler = (event) => {
        setPhone(event.target.value);
    }
    const onChangeAddressTextCreateHandeler = (event) => {
        setAddressText(event.target.value);
    }
    const onChangePostalCodeHandeler = (event) => {
        setPostalCode(event.target.value);
    }
  
    const DeleteAddress = () => {
        console.log(address.id);
        ApiDeleteAddress(localStorage.getItem('userToken'),address.id,(IsOk,response) => {
            if(IsOk){
                toast.success("آدرس حذف شد" , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });

                  ApiUserAddressList(localStorage.getItem('userToken'),(IsOk,response) => 
                  {
                      if(IsOk)
                      {
                          setUserAddressList(response);
                  
                      }
                      else{
                          console.log(response);
                          }
                  });
                 
            }else{
          
            }
        });
    }

    const EditAddress = () => {
        console.log("dsdsds");
        const AddressData = {
            Id_edit: address.id,
            UserId_edit : 0,
            StateId_edit : StateId,
            CityId_edit : CityId,
            CountryId_edit : 1,
            FirstName_edit :  FirstName,
            LastName_edit : LastName,
            Mobile_edit : Mobile,
            Phone_edit : Phone,
            PostalCode_edit : PostalCode,
            AddressText_edit : AddressText,
           
            // UserId_edit: 0,
            // CountryId_edit: 0,
            // CountryName: "string",
            // StateId_edit: 0,
            // StateName: "string",
            // CityId_edit: 0,
            // CityName: "string",
            // FirstName_edit: "string",
            // LastName_edit: "string",
            // Mobile_edit: "string",
            // Phone_edit: "string",
            // PostalCode_edit: "string",
            // AddressText_edit: "string"
        }

  
        ApiEditAddress(localStorage.getItem('userToken'),AddressData,(IsOk,response) => 
        {
            if(IsOk)
            {
                
                toast.success("آدرس شما با موفقیت ویرایش شد" , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
                ApiUserAddressList(localStorage.getItem('userToken'),(IsOk,response) => 
                {
                    if(IsOk)
                    {
                        setUserAddressList(response);
                
                    }
                    else{
                        console.log(response);
                        }
                });
            }
            else{
                console.log(response);
            }
        });
    }
    
    return (
        <>
         <div class="modal fade" id="staticBackdroptwo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">تغییر آدرس</h1>
                                <button type="button" className={`${styles.btnclose} btn-close`} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                
                            <table>
                                    <tr>
                                        <td className={styles.namemodalcheckout}><label>نام</label></td>
                                        <td className={styles.namemodalcheckout}><label>نام خانوادگی</label></td>
                                    </tr>
                                </table>
                                
                                <div className={styles.divinput}>
                                    
                               
                                                    <input onChange={onChangeFirstNameCreateHandeler} type='text' className={styles.nameinputcheckout}></input>
                                                    
                                                    <input onChange={onChangeLastNameCreateHandeler} type='text' className={styles.nameinputcheckout}></input>
                                </div>
                                <label className={styles.labels}>شماره تماس</label><br></br>
                                                <input onChange={onChangeMobileCreateHandeler} type='tel' className={styles.inputscheckout}></input>
                
                                                <label className={styles.labels}>تلفن ثابت</label><br></br>
                                                <input onChange={onChangePhoneHandeler} type='tel' className={styles.inputscheckout}></input>
                
                                                <label className={styles.labels}>آدرس دقیق</label><br></br>
                                                <input onChange={onChangeAddressTextCreateHandeler} type='text' className={styles.inputscheckout}></input>
                
                                                <div className={styles.addlabel}>
                                                <label className={styles.addresslabel}>استان</label>
                                                <label className={styles.addresslabel}>شهر</label>
                                                <label className={styles.addresslabel}>کدپستی</label>
                                                </div>
                                                
                                                <div className={styles.divinputaddress}>
                                                    
                                                    <select onChange={onChangeStateCreateHandeler} className={styles.addinput}>
                                                        {
                                                            States.map((state,index) => <option  value={state.id}>{state.stateName}</option> )
                                                        }
                                                    </select>
                                                    
                                                    <select onChange={onChangeCityCreateHandeler} className={styles.addinput}>
                                                        {
                                                            Cities.map((city,index) => <option  value={city.id}>{city.cityName}</option> )
                                                        }
                                                    </select>
                                                    <input type='number' onChange={onChangePostalCodeHandeler} className={styles.addinput}></input>
                
                                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={EditAddress} class="btn btn-success" >ثبت تغییرات</button>
                            </div>
                            </div>
                        </div>
                        </div>
        <div className={ styles.addressnone}>
                        
                        <button  type="button" >
                        <div className={styles.addresstitlediv}>
                        <FontAwesomeIcon  data-bs-toggle="modal" data-bs-target="#staticBackdroptwo" icon={faPenSquare} className={styles.editicon}></FontAwesomeIcon><FontAwesomeIcon  onClick={DeleteAddress} className={styles.removeicon} icon={faRemove} ></FontAwesomeIcon>
                        
                        </div></button>
                    
                        <strong className={styles.addresstitle}>{address.mobile}</strong>
                
                        <p>{address.cityDTO.cityName}</p>
                        <p>{address.stateDTO.stateName}</p>
                
                       
                    </div>
        </>
        
    );
};

export default AddressCard;