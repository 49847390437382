import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { ApiProducts } from '../Api/ApiProducts';
import { PageLoaderContext } from './PageLoaderContext';
export const ProductContext = createContext();
const ProductProvider = ({children}) => {
    const PageLoader = useContext(PageLoaderContext);
    const [products,setProducts] = useState([]);
    const [productConfig,setProductConfing] = useState({});
    const [selectedCategory,setSelectedCategory] = useState(" ");
    const [selectProductTitle,setSelectProductTitle] = useState(" ");
    const [selectedMaxPrice,setSelectedMaxPrice] = useState(0);
    const [selectMinPrice,setSelectMinPrice] = useState(0);
    useEffect(()=>{
        let pageLoaderCounter = 0;
        pageLoaderCounter = PageLoader.CounterPageLoader;
        pageLoaderCounter += 1
        ApiProducts(" ",0,0," ",(IsOk,response) => 
        {
            if(IsOk)
            {
                setProducts(response.products);
                delete response.products;
                setProductConfing(response);
                pageLoaderCounter -= 1;
                PageLoader.setCounterPageLoader(pageLoaderCounter);
            }
            else{
                console.log(response);
            }
        });
     
    },[])
    const ProductFilterCategory = (selectedCategory) => 
    {
        setSelectedCategory(selectedCategory);
        ApiProducts(selectedCategory,selectedMaxPrice,selectMinPrice,selectProductTitle,(IsOk,response) => 
        {
            if(IsOk){
                setProducts(response.products);
            }
            else{
                console.log(response);
            }
        });
    }
    const ProductFilterPrice = (selectmaxprice,selectminprice) => {
        setSelectedMaxPrice(selectmaxprice);
        setSelectMinPrice(selectminprice);

        ApiProducts(selectedCategory,selectmaxprice,selectminprice,selectProductTitle,(IsOk,response) => 
        {
            if(IsOk){
                setProducts(response.products);

             
            }
            else{
                console.log(response);
            }
        });
    }
    const ProductFilterTitle = (selectproducttitle) => {
        setSelectProductTitle(selectproducttitle);
        ApiProducts(selectedCategory,selectedMaxPrice,selectMinPrice,selectproducttitle,(IsOk,response) => 
        {
            if(IsOk){
                setProducts(response.products);
            }
            else{
                console.log(response);
            }
        });
    }
    return (
        <ProductContext.Provider value={{productConfig,products,selectedCategory,ProductFilterCategory,ProductFilterPrice,ProductFilterTitle}}>
            {children}
        </ProductContext.Provider>
    );
};

export default ProductProvider;