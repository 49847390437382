import React from 'react';
import Baner from './Baner';
import Categories from './Categories/Categories';
// import Logos from './Logos';
import Products from './Products';
import style from './Landing.module.css'
import { OrderContext } from '../Context/OrderProvider';
import 'bootstrap/dist/css/bootstrap.css'
import OrderSidbar from './Order/OrderSidbar';
import { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { useState } from 'react';
import { ProductContext } from '../Context/ProductProvider';
import catimg from '../images/apple.jpg'
//matrialui
import './Landing.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useEffect } from 'react';
import Pageloading from './Pageloading';
import { PageLoaderContext } from '../Context/PageLoaderContext';
import CategoriesMobile from './Categories/CategoriesMobile';




  
  function valuetext(rangepricevalue) {
    return `${rangepricevalue}`;
  }


const Landing = () => {
    const ProductsContext = useContext(ProductContext);
    const orderconfig = useContext(OrderContext);
    const PageLoader = useContext(PageLoaderContext);
    const [showFilterPrice,setShowFilterPrice] = useState(false)
    const [rangepricevalue, setRangepricevalue] = useState([0, 2000000]);    

    const closeHandler = () => {
        orderconfig.setIsopen(false)
        orderconfig.setIsclose (true)     
      }
      
   
    
      const onChangeRangepriceHandler = (event, newValue) => {
        setRangepricevalue(newValue);
        ProductsContext.ProductFilterPrice(newValue[1],newValue[0]);
      };




    
    return (
        PageLoader.CounterPageLoader === 0 ?
        <div>
            
            <div className={style.btnorder}>
                <OrderSidbar/>
            </div>
            <div onClick={closeHandler } className={orderconfig.isopen ? style.containeropen : style.container }>
                <div className={style.productsection}>
                <Baner/>
                <button className={style.filterpricebtn} onClick={() => {showFilterPrice ? setShowFilterPrice(false) : setShowFilterPrice(true)}}>فیلتر قیمت</button>
                <button className={style.mobilecategorybtn}>دسته بندی</button>
               <div className={showFilterPrice ? style.card : style.cardhide}>
               

                    <div className={style.rangeslider}>
                        <Slider
                            value={rangepricevalue}
                            onChangeCommitted={onChangeRangepriceHandler}
                            valueLabelDisplay="auto"
                            max={ProductsContext.productConfig.filterMaxPrice}
                            min={0}
                        />
                           <div className={style.showprice}>
                            <div className={style.price}><strong><span>{rangepricevalue[1].toLocaleString()}</span></strong><span>تومان&nbsp;</span></div>
                                 <span>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</span>
                            <div className={style.price}><strong><span>{rangepricevalue[0].toLocaleString()}</span></strong><span>تومان&nbsp;</span></div>
                        </div>
                    </div>
                </div>
               
                 <Products />
                
                </div>
                <div className={style.categoriesection}>
                    <Categories />
                </div>
            </div>
           
            <ToastContainer />
        </div>
        :<Pageloading/>
    );
};

export default Landing;