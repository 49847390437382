import React, { useEffect, useState, useContext} from 'react';
import style from './products.module.css';
import './products.css'
import { ApiProducts } from '../Api/ApiProducts';
import 'bootstrap/dist/css/bootstrap.css'
import Product from './Products/Product';
import { ProductContext } from '../Context/ProductProvider';
import Pageloading from './Pageloading';
import { Button } from '@material-ui/core';
const Products = () => {
    const ProductsContext = useContext(ProductContext);
    const [numberOfDisplayProduct,setNumberOfDisplayProduct] = useState(4);
    
    // const Apiproducts =createContext()
    return (
     
      
        <div className={`${style.container} row`}>
            {/* <div className={style.category}>category</div>
            <div className={`${style.product}`}></div>
            <div>
                <button  onClick={() => {console.log(categories)}}>naser</button>
            </div> */}
           
            {
                ProductsContext.products.length ? 
                ProductsContext.products.slice(0,numberOfDisplayProduct).map(product => <Product key={product.productId} product={product} />)
                :
                <Pageloading/>
            }
            {
                 ProductsContext.products.length > numberOfDisplayProduct ? 
                 <Button className={style.pageloadingstyle} onClick={() => { setNumberOfDisplayProduct(numberOfDisplayProduct + numberOfDisplayProduct) }} variant="outlined">
                    --- مشاهده بیشتر  ---
                 </Button> : 
                 <></>
            }
               
            
        
            
        </div>
        
    
    );
};

export default Products;