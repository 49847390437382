import React from 'react';
import { Link } from 'react-router-dom';
import style from './userpanelnavbar.module.css';
import { useState } from 'react';
const UserpanelNavbar = () => {
    const  [dashboard,SetDashboard] = useState(false);
    const [changepassword,SetChangepassword] = useState(false);
    const [order,SetOrder] = useState(false);
    const [interest,SetInterest] = useState(false);


  
      
    
    
        const dashboardHandler  = () =>{
            SetDashboard(true);
            SetOrder(false);
            SetInterest(false);
            SetChangepassword(false);
            
           
        }
        const changepasswordHandler  = () =>{
            SetChangepassword(true);
            SetOrder(false);
            SetInterest(false);
            SetDashboard(false);
        }
        const orderHandler  = () =>{
            SetOrder(true);
            SetInterest(false);
            SetDashboard(false);
            SetChangepassword(false);
        }
        const interestHandler  = (e) =>{
            SetInterest(true);
            SetChangepassword(false);
            SetOrder(false);
            SetDashboard(false);
          
            
        }


  
    return (
        <div className={style.container}>
            <Link aria-expanded={dashboard} to="/userpanel" className={style.activenavbar} onMouseEnter={dashboardHandler}>داشبورد</Link>
            <Link aria-expanded={changepassword} to="/userpanel/changepassword" className={style.activenavbar}  onMouseEnter={changepasswordHandler} >تغییر رمز</Link>
            <Link aria-expanded={order} to="/userpanel/orders" className={style.activenavbar} onMouseEnter={orderHandler}>سفارشات</Link>
            <Link aria-expanded={interest} to="/userpanel/wishlist" className={style.activenavbar} onMouseEnter={interestHandler}>علاقه مندی ها</Link>
        </div>
    );
};

export default UserpanelNavbar;