import { faPenSquare, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { ApiCreateAddress, ApiStatesAndCity } from '../../../Api/ApiAddress';
import { ApiGetShippingMethodSeller, ApiPaymentOrder } from '../../../Api/ApiOrder';
import { ApiUserAddressList } from '../../../Api/ApiUser';
import { OrderContext } from '../../../Context/OrderProvider';
import AddressCard from './AddressCard';
import styles from './checkout.module.css';
import CheckoutProductOrder from './CheckoutProductOrder';
import ShippingMethodSeller from './ShippingMethodSeller';

const Checkout = () => {
    const orderconfig = useContext(OrderContext);
    const [UserAddressList,setUserAddressList] = useState([]);
    const [ShippingMethodSellers,setShippingMethodSellers] = useState([]);
    const [States,setStates] = useState([]);
    const [Cities,setCities] = useState([]);
    const [StateId,setStateId] = useState(1);
    const [CityId,setCityId] = useState(1);
    const [FirstName,setFirstName] = useState(""); 
    const [LastName,setLastName] = useState("");
    const [Mobile,setMobile] = useState("");
    const [Phone,setPhone] = useState("");
    const [PostalCode,setPostalCode] = useState("");
    const [AddressText,setAddressText] = useState("");
    useEffect(()=>{

        ApiUserAddressList(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                setUserAddressList(response);
                
            }
            else{
                console.log(response);
            }
        });

        ApiGetShippingMethodSeller(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                setShippingMethodSellers(response);
                
            }
            else{
                console.log(response);
            }
        });

        ApiStatesAndCity(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                setStates(response);
                setCities(response[0].cities);
                setStateId(response[0].id);
                setCityId(response[0].cities[0].id)
            }
            else{
                console.log(response);
            }
        });

     
    },[])

    const PayOrder = () => {
        ApiPaymentOrder(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(!IsOk)
            {
                console.log(response);
            }
        });
    }

    const onChangeStateCreateHandeler = (event) => {
        setStateId(event.target.value);
        for (let i = 0; i < States.length; i++) {
            if(States[i].id == event.target.value){
                setCities(States[i].cities);
            }
        }
        
    }
    const onChangeCityCreateHandeler = (event) => {
        setCityId(event.target.value);
    }
    const onChangeFirstNameCreateHandeler = (event) => {
        setFirstName(event.target.value);
    }
    const onChangeLastNameCreateHandeler = (event) => {
        setLastName(event.target.value);
    }
    const onChangeMobileCreateHandeler = (event) => {
        setMobile(event.target.value);
    }
    const onChangePhoneHandeler = (event) => {
        setPhone(event.target.value);
    }
    const onChangeAddressTextCreateHandeler = (event) => {
        setAddressText(event.target.value);
    }
    const onChangePostalCodeHandeler = (event) => {
        setPostalCode(event.target.value);
    }

    const CalculatOrderTotalProductPrice = () => 
    {
        var Sum = 0 
        for (let i = 0; i < orderconfig.productOrder.length; i++) {
            var orderPrice = (orderconfig.productOrder[i].price + orderconfig.productOrder[i].productDifferencPrice) * orderconfig.productOrder[i].count;
            Sum += orderPrice;  
        }
        return Sum;
    }
    const CalculatOrderTotalShippingPrice = () => 
    {
        if(orderconfig.orderDetail === undefined) return 0;
        var ShippingMethodPriceSum = 0
        for (let i = 0; i < orderconfig.orderDetail.orderOrderShippingMethods.length; i++) {
            ShippingMethodPriceSum += orderconfig.orderDetail.orderOrderShippingMethods[i].shippingMethodPrice
            
        }
        return ShippingMethodPriceSum;
    }
    const CalculatOrderTotalOrderPrice = () => 
    {
        if(orderconfig.orderDetail === undefined) return 0;
        var ProductPriceSum = 0 
        for (let i = 0; i < orderconfig.productOrder.length; i++) {
            var orderPrice = (orderconfig.productOrder[i].price + orderconfig.productOrder[i].productDifferencPrice) * orderconfig.productOrder[i].count;
            ProductPriceSum += orderPrice;  
        }
        var ShippingMethodPriceSum = 0
        for (let i = 0; i < orderconfig.orderDetail.orderOrderShippingMethods.length; i++) {
            ShippingMethodPriceSum += orderconfig.orderDetail.orderOrderShippingMethods[i].shippingMethodPrice
            
        }
        return ProductPriceSum + ShippingMethodPriceSum;
    }
    
    
    const CreateAddress = () => {
        const AddressData = {
            UserId_create : 0,
            StateId_create : StateId,
            CityId_create : CityId,
            CountryId_craete : 1,
            FirstName_create :  FirstName,
            LastName_create : LastName,
            Mobile_create : Mobile,
            Phone_create : Phone,
            PostalCode_create : PostalCode,
            AddressText_create : AddressText
        }
        
        ApiCreateAddress(localStorage.getItem('userToken'),AddressData,(IsOk,response) => 
        {
            if(IsOk)
            {
                toast.success("آدرس شما با موفقیت ثبت شد" , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
                ApiUserAddressList(localStorage.getItem('userToken'),(IsOk,response) => 
                {
                    if(IsOk)
                    {
                        setUserAddressList(response);
                
                    }
                    else{
                        console.log(response);
                        }
                });
            }
            else{
                toast.error(response , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }
    return (
        <div className={styles.container}>
            

            <div className={styles.forms}>
                <div className={styles.send}>
                    <div className={styles.titleformschekout}>
                        <div className={styles.headerformscheckout}>
                            <span className={styles.numberfield}>1</span>
                            <p className={styles.titlefield}>آدرس</p>
                        </div>
                     
                        <button type="button" className={styles.updateicon} data-bs-toggle="modal" data-bs-target="#staticBackdrop">افزودن آدرس<FontAwesomeIcon className={styles.updateiconcheckout} icon={faPlus}></FontAwesomeIcon></button>
                            
                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">آدرس جدید</h1>
                                <button type="button" className={`${styles.btnclose} btn-close`} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                
                                <table>
                                    <tr>
                                        <td className={styles.namemodalcheckout}><label>نام</label></td>
                                        <td className={styles.namemodalcheckout}><label>نام خانوادگی</label></td>
                                    </tr>
                                </table>
                                    
                                
                                <div className={styles.divinput}>
                                    
                                    <input onChange={onChangeFirstNameCreateHandeler} type='text' className={styles.nameinputcheckout}></input>
                                    
                                    <input onChange={onChangeLastNameCreateHandeler} type='text' className={styles.nameinputcheckout}></input>
                                </div>
                                <label className={styles.labels}>شماره تماس</label><br></br>
                                <input onChange={onChangeMobileCreateHandeler} type='tel' className={styles.inputscheckout}></input>

                                <label className={styles.labels}>تلفن ثابت</label><br></br>
                                <input onChange={onChangePhoneHandeler} type='tel' className={styles.inputscheckout}></input>

                                <label className={styles.labels}>آدرس دقیق</label><br></br>
                                <textarea onChange={onChangeAddressTextCreateHandeler}  className={styles.inputscheckout}></textarea>

                                <div className={styles.addlabel}>
                                <label className={styles.addresslabel}>استان</label>
                                <label className={styles.addresslabel}>شهر</label>
                                <label className={styles.addresslabel}>کدپستی</label>
                                </div>
                                
                                <div className={styles.divinputaddress}>
                                    
                                    <select onChange={onChangeStateCreateHandeler} className={styles.addinput}>
                                        {
                                            States.map((state,index) => <option  value={state.id}>{state.stateName}</option> )
                                        }
                                    </select>
                                    
                                    <select onChange={onChangeCityCreateHandeler} className={styles.addinput}>
                                        {
                                            Cities.map((city,index) => <option  value={city.id}>{city.cityName}</option> )
                                        }
                                    </select>
                                    <input type='number' onChange={onChangePostalCodeHandeler} className={styles.addinput}></input>

                                </div>
                            </div>

                            <div class="modal-footer">
                                <button onClick={CreateAddress} type="button" class="btn btn-success" data-bs-dismiss="modal">تایید</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className={styles.addressdiv}>
                   {
                       UserAddressList.map(address => <AddressCard OrderConfig={orderconfig} setUserAddressList={setUserAddressList} address={address} />)
                   }
                  
                    </div>
                </div>
                
                {
 
                    ShippingMethodSellers.map((shippingMethodSeller,Index) => <ShippingMethodSeller key={Index} OrderConfig={orderconfig} Index={Index} shippingMethodSeller={shippingMethodSeller} />)
                }
                
          
       
            </div>

            <div className={styles.checkout}>
                <p className={styles.yourorder}>سفارشات شما</p>
             
                
                   <table>
                   <tr>
                        <th className={styles.tdcheckouttitle}>نام محصول</th>
                        <th className={styles.tdcheckout}>تعداد</th>
                        <th className={styles.tdcheckout}>قیمت</th>
                    </tr>
                    {
                        orderconfig.productOrder.map(productOrder => <CheckoutProductOrder key={productOrder.id} productOrder={productOrder} />)
                        }
                   </table>
                       
                    
                   
                
                
                <hr></hr>
                <div className={styles.sum}>
                    <label className={styles.sumlabel}>جمع محصول</label>
                    <label className={styles.sumlabel}>{CalculatOrderTotalProductPrice()}</label>
                    <label className={styles.sumlabel}>هزینه ارسال</label>
                    <label className={styles.sumlabel}>{CalculatOrderTotalShippingPrice()}</label>
                    <label className={styles.sumlabel}>محموع سفارش</label>
                    <label className={styles.sumlabel}>{CalculatOrderTotalOrderPrice()}</label>
                </div>
                <button onClick={PayOrder} className={styles.pay}> پرداخت</button>
                
            </div> 



            <ToastContainer />
        </div>
    );
};

export default Checkout;