import React from 'react';
import styles from './ordersidbar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import { ProductThumbnailImage } from '../../Utils/PathExtention';
import { ApiDeleteProductFromOrder } from '../../Api/ApiOrder';
import { toast } from 'react-toastify';
const ProductOrderItem = ({productOrderItem,IncreasItem,DecreasItem,ReloadApiProductOrder}) => {
    const IncreasItemHandeler = () => {
        IncreasItem(productOrderItem.id);
    }
    const DecreasItemHandeler = () => {
        DecreasItem(productOrderItem.id);
    }
    const DeleteProductFromOrder = () => {
        ApiDeleteProductFromOrder(localStorage.getItem('userToken'),productOrderItem.id,(IsOk,response) => {
            if(IsOk){
                toast.success(response.message , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });  
                ReloadApiProductOrder();
            }else{
                console.log(response);
            }
        });
    }
    return (
   
            <div className={styles.productorder}>
                <button onClick={DeleteProductFromOrder} className={styles.removeproduct}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon>  </button>
            <span className={styles.price}>{((productOrderItem.price + productOrderItem.productDifferencPrice) * productOrderItem.count).toLocaleString()}تومان</span>
                <div className={styles.sectiondetile}>
                    <h6>{productOrderItem.title}</h6>
                    <p>{(productOrderItem.price + productOrderItem.productDifferencPrice).toLocaleString()}تومان</p>
                    {
                        productOrderItem.productOptions.map((productOption,index) => <span>{productOption.itemName}{index != productOrderItem.productOptions.length - 1 ? "," : " "}</span>)
                    }
                </div>
                <div className={styles.sectionimg}>
                    <img src={ProductThumbnailImage + productOrderItem.productImage}/>
                </div>
                <div className={styles.sectioncount}><button onClick={IncreasItemHandeler}>+</button><span>{productOrderItem.count}</span><button onClick={DecreasItemHandeler}>-</button></div>
                </div>
    );
};

export default ProductOrderItem;