import React from 'react';
import style from './productcomment.module.css'
import {UserAvatarOrigin} from '../../Utils/PathExtention'
const ProductComment = (props) => {
    return (
        <div className={style.commentbox}>
            <div className={style.navcomment}>
                <div><img src={UserAvatarOrigin + props.comment.avatar} alt=""/></div>
                <div  className={style.commentdata}>
                    <p className={style.lastname}>{props.comment.lastName}</p>
                    <p className={style.data}>{props.comment.createDate}</p>
                </div>
            </div>
            <div>
                {props.comment.comment}
            </div>
        </div>
    );
};

export default ProductComment;