import React, { useEffect } from 'react';
import { useState } from 'react';
import style from './productdetaile.module.css'
const ProductOption = (props) => {
    const [select,setSelect] = useState([])
    useEffect(() => 
    {
        setSelect(props.productoptionid);
    },[]);
    const SelectOptionItemIdHandeler = (index,itemId) => 
    {
        props.SelectOptionItemId(index,itemId)
        setSelect(itemId);
        console.log(select);
    }
    return (
        <div>
            <strong>{props.option.productOptionName}</strong> : 
            {
                props.option.productOptionItemDTOs.map(item => <p  className={props.productoptionid.some(i => i === item.itemId) ? style.select : ""} onClick={() => SelectOptionItemIdHandeler(props.index,item.itemId)}> <span>{item.itemName}</span> </p>)
            }
        </div>
    );
};

export default ProductOption;