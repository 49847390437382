import React from 'react';
import style from './changpassword.module.css';
import UserpanelNavbar from './UserpanelNavbar';
import { ApiChangePassword } from '../../Api/ApiUser';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
const ChangePassword = () => {
    const [currentPassword , setCurrentPassword] = useState('');
    const [newPassword , setNewPassword] = useState('');
    const [confirmNewPassword , setConfirmNewPassword] = useState('');
    
    const onChangePassword = () => 
    {
        const changePassword = 
        {   
            CurrentPassword : currentPassword,
            NewPassword : newPassword,
            ConfirmNewPassword : confirmNewPassword
        } 
        ApiChangePassword(localStorage.getItem('userToken'),changePassword,(IsOk,response) => 
        {
            if(IsOk)
            {
                toast.success("رمز عبور شما با موفقیت تغییر کرد" , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
            else{
                toast.error("لطفا از کلمه عبور جدید استفاده کنید" , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }
    return (
        <div className={style.changepasswordcontain}>
            <UserpanelNavbar/>

            <div className={style.changepasswordcontainer}>
               

                <div className={style.changepass}>

                <p className={style.titlefield}>تغییر رمز</p>

                <label className={style.changepasswordlabel}>رمز</label><br></br>
                <div className={style.inputdivchangepassword}>
                    <input onChange={(event) => setCurrentPassword(event.target.value)} className={style.changepasswordinput} type='password'></input><br></br>
                </div>

                <label className={style.changepasswordlabel}>رمز جدید</label><br></br>
                <div className={style.inputdivchangepassword}>
                    <input onChange={(event) => setNewPassword(event.target.value)} className={style.changepasswordinput} type='password'></input><br></br>
                </div>

                <label className={style.changepasswordlabel}>تکرار رمز جدید</label><br></br>
                <div className={style.inputdivchangepassword}>
                    <input onChange={(event) => setConfirmNewPassword(event.target.value)} className={style.changepasswordinput} type='password'></input><br></br>
                </div>

                <div className={style.buttondivchangepassword}>
                    <button type='button' onClick={onChangePassword} className={style.changepasswordbutton}>ذخیره</button>
                </div>
                        </div>
                        </div>
                        <ToastContainer />
                        </div>
                        
    );
};

export default ChangePassword;