import React, { useEffect, useState , useContext} from 'react';
import { useParams } from 'react-router-dom';
import { ApiCreateProductComment, ApiProduct, ApiProductGallery } from '../../Api/ApiProducts';
import {ApiProductComment} from '../../Api/ApiProducts'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery'
import image1 from '../../images/iphone10.jpg'
import image2 from '../../images/iphone11.jpg'
import image3 from '../../images/iphone12.jpg'
import { ProductGalleryImage, ProductGalleryThumbnailImage, ProductThumbnailImage } from '../../Utils/PathExtention';
import { ProductImage  } from '../../Utils/PathExtention';
import style from '../Products/productdetaile.module.css'
import ProductComment from './ProductComment';
import { UserContext } from '../../Context/UserInfo';
import Product from './Product';
import ProductOption from './ProductOption';
import { toast, ToastContainer } from 'react-toastify';
import { ApiAddToOrder } from '../../Api/ApiOrder';
import { OrderContext } from '../../Context/OrderProvider';
import OrderSidbar from '../Order/OrderSidbar';
import { PageLoaderContext } from '../../Context/PageLoaderContext';
import Pageloading from '../Pageloading';

 
//image gallery


const ProductDetail = () => {
    const userContext  =  useContext(UserContext);
    const orderContext = useContext(OrderContext);
    const PageLoader = useContext(PageLoaderContext);
    const [descriptiontab,setDescriptiontab] = useState(true);
    const [specificationtab,setSpecificationtab] = useState(false);
    const [commenttab,setCommenttab] = useState(false);
    const [productOptionId,setProductOptionId] = useState([]);
    const [productOptionItems,setProductOptionItems] = useState([]);
    const [productCount,setProductCount] = useState(1);
    const [productGallery,setProductGallery] = useState([]);
    const [productDifferencePrice,setProductDifferencePrice] = useState();
    const descriptionHandler =()=>{
        setDescriptiontab(true);
        setSpecificationtab(false);
        setCommenttab(false);
    }
    const specificationHandler = ()=>{
        setDescriptiontab(false);
        setSpecificationtab(true);
        setCommenttab(false);
    }
    const commentHondler= () =>{
        setDescriptiontab(false);
        setSpecificationtab(false);
        setCommenttab(true);
    }
    const [product,setProduct] = useState({})
    const [productComment,setProductComment] = useState({})
    let { id } = useParams();
    const SelectOptionItemId = (optionId,itemId) => 
    {
        const optionItemId = productOptionId;
        var oldItemId = optionItemId[optionId];
        var TempproductDifferencePrice = productDifferencePrice
        for (let i = 0; i < productOptionItems.length; i++) {

            if(productOptionItems[i].itemId == oldItemId){
                TempproductDifferencePrice -= productOptionItems[i].differencePrice;
            }
            if(productOptionItems[i].itemId == itemId){
                TempproductDifferencePrice += productOptionItems[i].differencePrice;
            }
        }
        
        setProductDifferencePrice(TempproductDifferencePrice);
      
        optionItemId.splice(optionId, 1 , itemId);
        setProductOptionId(optionItemId);

    }
    const onChanegProductCountHandeler = (event) => 
    {
        setProductCount(event.target.value);
    }
    const AddToCart = () => 
    {
        const Data = 
        {
            ProductId : product.productId,
            ProductOptionsId : productOptionId,
            Count : productCount
        }


        
        ApiAddToOrder(localStorage.getItem("userToken"),Data,(IsOk,response) => 
        {
            if(IsOk)
            {
                orderContext.ReloadApiProductOrder();
                toast.success('محصول به سبد خرید اضافه شد' , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
            else{
                
                if (response.response.status === 401) {
                    toast.error('لطفا اول لاگین کنید', {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });   
                }
            
            }
        });

    }
    useEffect(()=>{
        let pageLoaderCounter = 0;
        pageLoaderCounter = PageLoader.CounterPageLoader;
        pageLoaderCounter += 3;
        ApiProduct(id,(IsOk,response) => 
        {
            if(IsOk)
            {
                const optionItemId = [];
                const optionItems = [];
                var priceSum = 0
                setProduct(response);
                for (let i = 0; i < response.productOptions.length; i++) {
                    const item = response.productOptions[i].productOptionItemDTOs[0]
                    for (let j = 0; j < response.productOptions[i].productOptionItemDTOs.length; j++) {
                        optionItems.push(response.productOptions[i].productOptionItemDTOs[j]);
                        
                    }
                    priceSum += item.differencePrice;
                    optionItemId.push(item.itemId);
                }
                setProductOptionItems(optionItems);
                setProductOptionId(optionItemId);
                setProductDifferencePrice(priceSum);
                pageLoaderCounter -= 1;
                PageLoader.setCounterPageLoader(pageLoaderCounter);
            }
            else{
                console.log(response);
            }
        });
        ApiProductComment(id,(IsOk,response) => 
        {
            if(IsOk)
            {
                setProductComment(response);
                pageLoaderCounter -= 1;
                PageLoader.setCounterPageLoader(pageLoaderCounter);
            }
            else{
                console.log(response);
            }
        });
        ApiProductGallery(id,(IsOk,response) => 
        {
            if(IsOk)
            {
                setProductGallery(response);
                images.push();
                pageLoaderCounter -= 1;
                PageLoader.setCounterPageLoader(pageLoaderCounter);
            }
            else{
                console.log(response);
            }
        });
    },[])
    const sentCommentHandler =()=>{
        if(userContext.IsLogin){
            const ProductComment = 
            {
                Mobile : userContext.PhoneNumber,
                ProductId : product.productId,
                Rated : rating,
                Comment : textarea 
            }
            ApiCreateProductComment(userContext.UserToken,ProductComment,(IsOk,respone) => 
            {
                if(IsOk){
                    toast.success("نظر شما با موفقیت ثبت شد" , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }else{
                    toast.error('لطفا همه فیلدها را پر کنید' , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
            })
        }else{
            toast.error('لطفا وارد حساب کاربری خود شوید' , {
                position: "bottom-right",
                closeButton: true,
                closeOnClick: true,
              });
        }
        
    }
    
    //text box handler
    const [textarea,setTextarea]=useState("")
    const massagehandler = (event) =>{
        console.log(images);
        setTextarea(event.target.value)
    }
    //rating
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    
  
    const images = [
        {original: ProductImage + product.imageName ,thumbnail: ProductThumbnailImage + product.imageName},
    ];
    productGallery.map(item => images.push({
        original: ProductGalleryImage + item.imageName ,thumbnail: ProductGalleryThumbnailImage + item.imageName
    }))


    //return 
    return (
        PageLoader.CounterPageLoader === 0 ?
       <>
        <div className={`${style.container} container-fluid row`}>
                    
                    <div className={`${style.detail} col-sm-6  col-md-12 col-lg-12 col-xl-6 col-xxl-6 `}>
                        <h1>{product.title}</h1>
                     
                        <p className={style.p}>{product.shortDescription}</p>

                       
                        <span className={style.price}>تومان{(product.price + productDifferencePrice).toLocaleString()}</span>
                        <div className={style.btn}>
                            <button onClick={AddToCart}>افزودن به سبد خرید</button>
                        </div>
                        <hr className={style.hrdetile}/> 
                         <div className={style.categori}>
                            <span className={style.title}>دسته بندی:</span>
                            {product.productCategories !== undefined ? product.productCategories.map(item => <span key={item.categoryId}>{item.title}</span>) : <span></span>}
                            <span className={style.title}>موجودی در انبار:</span><span>{product.count}</span>
                        </div>
                      
                        <dia className={style.optiondetile}>
                            <div>
                                {
                                    product.productOptions !== undefined ? product.productOptions.map((option,index) => <ProductOption key={option.productOptionId} index={index} option={option} SelectOptionItemId={SelectOptionItemId} productoptionid={productOptionId}/>) : <strong></strong>
                                }
                            </div>
                            
                        </dia>
                        <div className={style.divcount}>
                            <spnan>تعداد:</spnan>
                             <input className={style.inputcount}  min={1} max={product.count} type="number" placeholder='1'  onChange={onChanegProductCountHandeler}  id="productCount" />
                        </div>
                        <div className={style.btnorder}>
                            <OrderSidbar/>
                        </div>
                    </div>
                    <div className={ `${style.gallery} col-sm-12  col-md-12 col-lg-12 col-xl-6 col-xxl-6`}>
                        <ImageGallery items={images} />    
                        
                    </div>
       
         
               
           
        </div>
               
        <div className={style.tab}>
                    <ul>
                        <li className={descriptiontab && style.activ} onClick={descriptionHandler}>توضیحات</li>
                        <li className={specificationtab && style.activ} onClick={specificationHandler}>بررسی</li>
                        <li className={commenttab && style.activ} onClick={commentHondler}>نظرات</li>
                    </ul>
        </div>
        {descriptiontab ?
         <div className={style.description}>
            { <div dangerouslySetInnerHTML={{ __html:(product.description) }} /> }
        </div>: ""}
        {specificationtab ?
         <div className={style.specification}>
            <p>توضیحات تخصصی</p>
        </div>: ""}
        {commenttab?
         <div className={style.comment}>
            {productComment.map(comment => <ProductComment comment={comment}/>)}
            <div className={style.commentbox}>
                <p>تعداد نظرات برای این محصول: <span>{productComment.length}</span></p>
                <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                    <button
                        type="button"
                        key={index}
                        className={index <= (hover || rating) ? style.on : style.off}
                        onClick={() => {setRating(index)
                            console.log(rating)
                        }}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        <span className="star">&#9733;</span>
                    </button>
                    );
                })}
                </div>
                <label>نظر شما:</label>
                <textarea value={textarea} onChange={massagehandler} className={style.textareacomment}></textarea>
                <button className={style.sendcomment} onClick={sentCommentHandler}>ثبت نظر</button>
            </div>
        </div>: ""}
        <ToastContainer />
       </>
       :<Pageloading/>

    );
};

export default ProductDetail;

// https://dev.to/michaelburrows/create-a-custom-react-star-rating-component-5o6     link rated





