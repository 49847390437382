import React from 'react';

import styles from './ordersidbar.module.css'
// import { useState } from 'react';
import { useContext } from 'react';
import { OrderContext } from '../../Context/OrderProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import ProductOrderItem from './ProductOrderItem';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import emptycart from '../../images/icons8-shopping-cart.gif'
const OrderSidbar = () => {
    const [orderTotalPrice,setOrderTotalPrice] = useState()
    const orderconfig= useContext(OrderContext)
   const openHandler = () => {
    orderconfig.setIsopen(true)
    orderconfig.setIsclose (false)
    console.log(orderconfig.isopen)
    console.log(orderconfig.isclose)
   }
  const closeHandler = () => {
    orderconfig.setIsopen(false)
    orderconfig.setIsclose (true)
    console.log(orderconfig.isopen)
    console.log(orderconfig.isclose)
  }
  const CalculatOrderTotalPrice = () => 
  {
    var Sum = 0 
    for (let i = 0; i < orderconfig.productOrder.length; i++) {
        var orderPrice = (orderconfig.productOrder[i].price + orderconfig.productOrder[i].productDifferencPrice) * orderconfig.productOrder[i].count;
        Sum += orderPrice;  
    }
    return Sum.toLocaleString();
  }

    return (
        <>
        <div  className={orderconfig.isopen ? styles.sidenavopen : styles.sidenavclose}>
{/* head order */}
        <div className={styles.headsidbar}>
                <button className={styles.btnclose} onClick={closeHandler}>
                 
                  
                   <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                    
                </button>
                <span className={styles.spn1}>
                    <span>{orderconfig.productOrder.length} item</span>
                    <svg width="14" height="16" class="shrink-0" viewBox="0 0 12.686 16"><g transform="translate(-27.023 -2)"><g transform="translate(27.023 5.156)"><g><path d="M65.7,111.043l-.714-9A1.125,1.125,0,0,0,63.871,101H62.459V103.1a.469.469,0,1,1-.937,0V101H57.211V103.1a.469.469,0,1,1-.937,0V101H54.862a1.125,1.125,0,0,0-1.117,1.033l-.715,9.006a2.605,2.605,0,0,0,2.6,2.8H63.1a2.605,2.605,0,0,0,2.6-2.806Zm-4.224-4.585-2.424,2.424a.468.468,0,0,1-.663,0l-1.136-1.136a.469.469,0,0,1,.663-.663l.8.8,2.092-2.092a.469.469,0,1,1,.663.663Z" transform="translate(-53.023 -101.005)" fill="currentColor"></path></g></g><g transform="translate(30.274 2)"><g><path d="M160.132,0a3.1,3.1,0,0,0-3.093,3.093v.063h.937V3.093a2.155,2.155,0,1,1,4.311,0v.063h.937V3.093A3.1,3.1,0,0,0,160.132,0Z" transform="translate(-157.039)" fill="currentColor"></path></g></g></g></svg>
                    </span>
            </div>
        <hr className={styles.hrorderside}/>
           
{/* body order */}        
{orderconfig.productOrder.length===0 ?  <div className={styles.imgcartwmpty}><img src={emptycart }  /><span>محصولی یافت نشد</span></div>: 
           <div className={styles.bodysidbar}>
                {
                     orderconfig.productOrder.map(productOrderItem => <ProductOrderItem key={productOrderItem.Id} productOrderItem={productOrderItem} IncreasItem={orderconfig.IncreasItemOrder} DecreasItem={orderconfig.DecreasItemOrder} ReloadApiProductOrder={orderconfig.ReloadApiProductOrder} />)
                }
                
                 <div className={orderconfig.productOrder.length >0 ?  styles .footersidbar : styles.footernone    } >
              <Link to='/checkout'>
                <button className={styles.footerbtn}>
                    <span className={styles.spanfooterone}>{CalculatOrderTotalPrice().toLocaleString()}تومان</span>
                    <span className={styles.spanfootertwo}>پرداخت</span>
                </button>
              </Link>
            </div>
            </div>
}
{/* footer order */}
           
      </div>
      <div className={ orderconfig.isopen ?  styles.apphide : styles.App }>
<button className={styles.btn} onClick={ openHandler}>
  <span className={styles.spn1}>
    <span>{orderconfig.productOrder.length} item</span>
    <svg width="14" height="16" class="shrink-0" viewBox="0 0 12.686 16"><g transform="translate(-27.023 -2)"><g transform="translate(27.023 5.156)"><g><path d="M65.7,111.043l-.714-9A1.125,1.125,0,0,0,63.871,101H62.459V103.1a.469.469,0,1,1-.937,0V101H57.211V103.1a.469.469,0,1,1-.937,0V101H54.862a1.125,1.125,0,0,0-1.117,1.033l-.715,9.006a2.605,2.605,0,0,0,2.6,2.8H63.1a2.605,2.605,0,0,0,2.6-2.806Zm-4.224-4.585-2.424,2.424a.468.468,0,0,1-.663,0l-1.136-1.136a.469.469,0,0,1,.663-.663l.8.8,2.092-2.092a.469.469,0,1,1,.663.663Z" transform="translate(-53.023 -101.005)" fill="currentColor"></path></g></g><g transform="translate(30.274 2)"><g><path d="M160.132,0a3.1,3.1,0,0,0-3.093,3.093v.063h.937V3.093a2.155,2.155,0,1,1,4.311,0v.063h.937V3.093A3.1,3.1,0,0,0,160.132,0Z" transform="translate(-157.039)" fill="currentColor"></path></g></g></g></svg>
  </span>
  <span className={styles.spn2}>{CalculatOrderTotalPrice()}تومان</span>
</button>
</div>
        </>
    );
};

export default OrderSidbar;