import { GetAxiosInstance } from "./Api"

export const ApiProducts =(Category=" ",SelectedMaxPrice=0,SelectedMinPrice=0,ProductTitle=" ",callback) =>{
    if(SelectedMaxPrice !== 0 || SelectedMinPrice !== 0){
        GetAxiosInstance().get(`/Product/Products?Category=${Category}&SelectedMaxPrice=${SelectedMaxPrice}&SelectedMinPrice=${SelectedMinPrice}&ProductTitle=${ProductTitle}`).then(res =>{
            callback(true,res.data);
        }).catch(err=>{
            callback(false,err.message);
        })
    }
    else if(Category !== " "){
        console.log("test")
        GetAxiosInstance().get(`/Product/Products?Category=${Category}`).then(res =>{
            callback(true,res.data);
        }).catch(err=>{
            callback(false,err.message);
        })
    }
    else if (ProductTitle !== " ") {
        GetAxiosInstance().get(`/Product/Products?ProductTitle=${ProductTitle}`).then(res =>{
            callback(true,res.data);
        }).catch(err=>{
            callback(false,err.message);
        })
    }
    else {
        GetAxiosInstance().get(`/Product/Products`).then(res =>{
            callback(true,res.data);
        }).catch(err=>{
            callback(false,err.message);
        }) 
        console.log("test")
    }
    
    
};

export const ApiProductGallery = (productId,callback) =>{
    GetAxiosInstance().get(`Product/ProductGallery?productId=${productId}`).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}
export const ApiCategory = (callback) =>{
    GetAxiosInstance().get(`Product/Categories`).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}
export const ApiProduct = (productId,callback) =>{
    GetAxiosInstance().get(`Product/Product?productId=${productId}`).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}
export const ApiProductComment = (productId,callback) =>{
    GetAxiosInstance().get(`Product/ProductComment?productId=${productId}`).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}
export const ApiCreateProductComment = (userToken,createProductComment,callback) => 
{
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Product/ProductComment`,createProductComment,config).then(res => 
        {
            callback(true,res.data);
        }).catch(err => 
            {
                callback(false,err.message);
            })
}

export const ApiAddProductToFavoriteList = (userToken,productId,callback) => 
{
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Product/AddProductToFavoriteList?productId=${productId}`,productId,config).then(res => 
        {
            callback(true,res.data);
        }).catch(err => 
        {
            callback(false,err.message);
        })
}

export const ApiProductFavoriteList = (userToken,callback) => 
{
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get(`Product/ProductFavoriteList`,config).then(res => 
        {
            callback(true,res.data);
        }).catch(err => 
        {
            callback(false,err.message);
        })
}

export const ApiDeleteProductFromFavoriteList = (userToken,productId,callback) => 
{
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().delete(`Product/DeleteProductFromFavoriteList/${productId}`,config).then(res => 
        {
            callback(true,res.data);
        }).catch(err => 
        {
            callback(false,err.message);
        })
}