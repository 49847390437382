import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { ApiCallBackPayment } from '../../../Api/ApiOrder';
import { OrderContext } from '../../../Context/OrderProvider';
import style from './callbackpayment.module.css'
import successtick from '../../../images/success-tick-svgrepo-com(1).svg'
import failtick from '../../../images/White_x_in_red_rounded_square.svg.png'
const CallbackPayment = () => {
    const orderConfig = useContext(OrderContext);
    const [dataCallBack, setDataCallBack] = useState(0);
    const [CallBackUrl,setCallBackUrl] = useState(window.location.href);
    useEffect(() => {
        if (CallBackUrl.indexOf("NOK") === -1) {
            var OrderAuthority = {
            
                Authority: CallBackUrl.substr(CallBackUrl.indexOf("=0") + 1, 36),
                Status : true
            };
       
            ApiCallBackPayment(localStorage.getItem('userToken'),OrderAuthority, (IsOk, data) => {
                if (IsOk) {
                    
                orderConfig.ReloadApiProductOrder();    
                setDataCallBack(data);
            

                } else {
                
                }
            });
            
        } else {
            
        }
    },[])
    if(CallBackUrl.indexOf("NOK") === -1){
        return (
          
            <div className={style.continer}>
            <div className={style.containbackground}>
                <div className={style.paymentstatus}><span><img src={successtick}/></span></div>
                <div className={style.textpaymentstatus}><h1>پرداخت انجام شد!</h1></div>
                <div className={style.username}><h3>محمد حسین مشهدی محمد</h3></div>
                <div className={style.ditailepayment}><span>زمان پرداخت </span><p>{dataCallBack.paymentDate}</p></div>
                <div className={style.ditailepayment}><span>کد رهگیری </span><p>{dataCallBack.refcode}</p></div>
            </div>
        </div>
           
        );
    }else{
        return (
               
            <div className={style.continer}>
            <div className={style.containbackground}>
                <div className={style.failpaymentstatus}><span><img className={style.failpaymentstatusimage} src={failtick}/></span></div>
                <div className={style.failtextpaymentstatus}><h1>پرداخت انجام نشد!</h1></div>
                <div className={style.username}><h3>محمدحسین <span>عزیز</span></h3></div>
                <div className={style.username}><h3> لطفا مجدد تلاش کنید.</h3></div>
            </div>
          
        </div>
           
        );
    }
    
    
};

export default CallbackPayment;