import React from 'react';
import { faPenSquare, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './checkout.module.css';
import { ApiSelectShippingMethodOrder } from '../../../Api/ApiOrder';
import { toast } from 'react-toastify';
const ShippingMethod = ({shippingMethod,OrderConfig}) => {
    const SelectShippingMethodOrder = () => {
        ApiSelectShippingMethodOrder(localStorage.getItem('userToken'),shippingMethod.id,(IsOk,response) => {
            if(IsOk){
                if(response.statusCode === 501) {
                    toast.error(response.message , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                else if(response.statusCode === 502){
                    toast.error(response.message , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                else if(response.statusCode === 503){
                    toast.error(response.message , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                else if(response.statusCode === 200){
                    toast.success('روش مورد نظر انتخاب شد' , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                OrderConfig.ReloadApiProductOrder();
            }else{
                toast.error(response.message , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }
    return (
        
        <div  onClick={SelectShippingMethodOrder} className={ OrderConfig.orderDetail?.orderOrderShippingMethods.some(item => item.shippingMethodId === shippingMethod.id) ?  styles.address : styles.addressnone}>
            <strong>{shippingMethod.name}</strong>
            <p>هزینه ارسال : {shippingMethod.price}</p>
        </div>
       
     
    );
};

export default ShippingMethod;