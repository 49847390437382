import { GetAxiosInstance } from "./Api"




export const ApiGetAddressDetail = (userToken,AddressId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get(`Address/GetAddressDetail/${AddressId}`,AddressId,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiStatesAndCity = (userToken,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get(`Address/StatesAndCity`,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiCreateAddress = (userToken,Address,callback) => {
    const config = {
        headers : {
            Authorization : `Bearer ${userToken}`
        }
    };
    GetAxiosInstance().post(`Address/create-address`,Address,config).then(res => {
        callback(true,res.data);
    }).catch(err => {
        callback(false,err.message);
    })
}

export const ApiEditAddress = (userToken,Address,callback) => {
    const config = {
        headers : {
            Authorization : `Bearer ${userToken}`
        }
    };
    GetAxiosInstance().post(`Address/edit-address`,Address,config).then(res => {
        callback(true,res.data);
    }).catch(err => {
        callback(false,err.message);
    })
}

export const ApiDeleteAddress = (userToken,AddressId,callback) => {
    console.log(`Address/delete-address/${AddressId}`);
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().delete(`Address/delete-address/${AddressId}`,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}


