import { GetAxiosInstance } from "./Api"


export const ApiSlider = (callback) =>{
    GetAxiosInstance().get(`Common/Slider`).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiSiteSetting = (callback) =>{
    GetAxiosInstance().get(`Common/SiteSetting`).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}


export const ApiCreateContactUs = (userToken,contactUsData,callback) =>{
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Common/CreateContactUs`,contactUsData,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}
 