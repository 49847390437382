import React, { createContext, useState } from 'react';

export const PageLoaderContext = createContext();
const PageLoaderProvider = ({children}) => {
    const [CounterPageLoader,setCounterPageLoader] = useState(0);
    return (
        <PageLoaderContext.Provider value={{CounterPageLoader,setCounterPageLoader}} >
            {children}
        </PageLoaderContext.Provider>
    );
};

export default PageLoaderProvider;