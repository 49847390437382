import React from 'react';
import UserpanelNavbar from './UserpanelNavbar';
import style from './dashbord.module.css';
import image from '../../images/apple.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ChangePassword from './ChangePassword';
import { useEffect } from 'react';
import { useState } from 'react';
import { ApiCreateAddress, ApiStatesAndCity } from '../../Api/ApiAddress';
import { ApiEditProfile, ApiUserAddressList } from '../../Api/ApiUser';
import AddressCard from './AddressCard';
import { ToastContainer, toast } from 'react-toastify';
import { UserAvatarThumb } from '../../Utils/PathExtention';
const Dashbord = () => {
    const [States,setStates] = useState([]);
    const [Cities,setCities] = useState([]);
    const [StateId,setStateId] = useState(1);
    const [CityId,setCityId] = useState(1);
    const [FirstName,setFirstName] = useState(""); 
    const [LastName,setLastName] = useState("");
    const [Mobile,setMobile] = useState("");
    const [Phone,setPhone] = useState("");
    const [PostalCode,setPostalCode] = useState("");
    const [AddressText,setAddressText] = useState("");
    const [UserAddressList,setUserAddressList] = useState([]);
  
    useEffect(()=>{
        ApiStatesAndCity(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                console.log(response[0].cities);
                setStates(response);
                setCities(response[0].cities);
                setStateId(response[0].id);
                setCityId(response[0].cities[0].id)
            }
            else{
                console.log(response);
            }
        });

        ApiUserAddressList(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                setUserAddressList(response);
                
            }
            else{
                console.log(response);
            }
        });


     
    },[])

    const onChangeStateCreateHandeler = (event) => {
        setStateId(event.target.value);
        for (let i = 0; i < States.length; i++) {
            if(States[i].id == event.target.value){
                setCities(States[i].cities);
            }
        }
        
    }
    const onChangeCityCreateHandeler = (event) => {
        setCityId(event.target.value);
    }
    const onChangeFirstNameCreateHandeler = (event) => {
        setFirstName(event.target.value);
    }
    const onChangeLastNameCreateHandeler = (event) => {
        setLastName(event.target.value);
    }
    const onChangeMobileCreateHandeler = (event) => {
        setMobile(event.target.value);
    }
    const onChangePhoneHandeler = (event) => {
        setPhone(event.target.value);
    }
    const onChangeAddressTextCreateHandeler = (event) => {
        setAddressText(event.target.value);
    }
    const onChangePostalCodeHandeler = (event) => {
        setPostalCode(event.target.value);
    }


    const CreateAddress = () => {
        const AddressData = {
            UserId_create : 0,
            StateId_create : StateId,
            CityId_create : CityId,
            CountryId_craete : 1,
            FirstName_create :  FirstName,
            LastName_create : LastName,
            Mobile_create : Mobile,
            Phone_create : Phone,
            PostalCode_create : PostalCode,
            AddressText_create : AddressText
        }
        
        ApiCreateAddress(localStorage.getItem('userToken'),AddressData,(IsOk,response) => 
        {
            if(IsOk)
            {
                toast.success("آدرس شما با موفقیت ثبت شد" , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
                ApiUserAddressList(localStorage.getItem('userToken'),(IsOk,response) => 
                {
                    if(IsOk)
                    {
                        setUserAddressList(response);
                
                    }
                    else{
                        console.log(response);
                        }
                });
            }
            else{
                toast.error(response , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }

    const EditProfile = () => {
        const image = document.getElementById('imageProfile').files[0];
        const file = new FormData();
        file.append('Image',image);
        ApiEditProfile(localStorage.getItem('userToken'),FirstName,LastName,file ,(IsOk , backData)=>{
                if(IsOk){
                    console.log("csdcsdsdcsdcsdcsdcsdsdc");
                    toast.success("ویرایش انجام شد" , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                      localStorage.setItem('userFirstName', FirstName);
                      localStorage.setItem('userLastName', LastName);
                }else{
                    console.log(backData);
                }
          })
    }





























    return (
        <div className={style.container}>
            <UserpanelNavbar/>
            <div className={style.dashbordcontainer}>

                <div className={style.informationuser}>

                
                <div className={style.profileimagediv}>
                    <img alt='pic' className={style.profileimage} src={UserAvatarThumb + localStorage.getItem('userAvatar')}/><br></br>
                    <div className={style.chooseprofileimagediv}>
                      <label className={style.choosephotolabel}>انتخاب عکس:</label>
                      <input type='file' id='imageProfile' className={style.imageinput}></input>
                    </div>
                  
                        
                </div>
                

                <label className={style.dashbordlabel}>نام</label><br></br>
                <div className={style.inputdivdashbord}>
                    <input placeholder={localStorage.getItem('userFirstName')} onChange={onChangeFirstNameCreateHandeler} className={style.dashbordinput} type='text'></input><br></br>
                </div>

                <label className={style.dashbordlabel}>نام خانوادگی</label><br></br>
                <div className={style.inputdivdashbord}>
                    <input placeholder={localStorage.getItem('userLastName')} onChange={onChangeLastNameCreateHandeler} className={style.dashbordinput} type='text'></input><br></br>
                </div>

                <div className={style.buttondivdashbord}>
                    <button onClick={EditProfile} type='button' className={style.dashbordbutton}>ذخیره</button>
                </div>

                </div>
                <div className={style.forms}>
                <div className={style.send}>
                    <div className={style.titleformschekout}>
                        <div className={style.headerformscheckout}>
                            <p className={style.titlefield}>آدرس</p>
                        </div>
                     
                        <button type="button" className={style.updateicon} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            بروزرسانی<FontAwesomeIcon className={style.updateiconcheckout} icon={faPlus}></FontAwesomeIcon></button>
                     </div>
                </div>
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">آدرس جدید</h1>
                                <button type="button" className={`${style.btnclose} btn-close`} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                
                                <table>
                                    <tr>
                                        <td className={style.namemodalcheckout}><label>نام</label></td>
                                        <td className={style.namemodalcheckout}><label>نام خانوادگی</label></td>
                                    </tr>
                                </table>
                                    
                                
                                <div className={style.divinput}>
                                    
                                    <input onChange={onChangeFirstNameCreateHandeler} type='text' className={style.nameinputcheckout}></input>
                                    
                                    <input onChange={onChangeLastNameCreateHandeler} type='text' className={style.nameinputcheckout}></input>
                                </div>
                                <label className={style.labels}>شماره تماس</label><br></br>
                                <input onChange={onChangeMobileCreateHandeler} type='tel' className={style.inputscheckout}></input>

                                <label className={style.labels}>تلفن ثابت</label><br></br>
                                <input onChange={onChangePhoneHandeler} type='tel' className={style.inputscheckout}></input>

                                <label className={style.labels}>آدرس دقیق</label><br></br>
                                <textarea onChange={onChangeAddressTextCreateHandeler}  className={style.inputscheckout}></textarea>

                                <div className={style.addlabel}>
                                <label className={style.addresslabel}>استان</label>
                                <label className={style.addresslabel}>شهر</label>
                                <label className={style.addresslabel}>کدپستی</label>
                                </div>
                                
                                <div className={style.divinputaddress}>
                                    
                                    <select onChange={onChangeStateCreateHandeler} className={style.addinput}>
                                        {
                                            States.map((state,index) => <option  value={state.id}>{state.stateName}</option> )
                                        }
                                    </select>
                                    
                                    <select onChange={onChangeCityCreateHandeler} className={style.addinput}>
                                        {
                                            Cities.map((city,index) => <option  value={city.id}>{city.cityName}</option> )
                                        }
                                    </select>
                                    <input type='number' onChange={onChangePostalCodeHandeler} className={style.addinput}></input>

                                </div>
                            </div>

                            <div class="modal-footer">
                                <button onClick={CreateAddress} type="button" class="btn btn-success" data-bs-dismiss="modal">تایید</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className={style.addressdiv}>
                    {
                       UserAddressList.map(address => <AddressCard setUserAddressList={setUserAddressList} address={address} />)
                    }
                  
                    </div>
                    </div>
                    
                   
                </div>
                {/* <ChangePassword/>     */}
                <ToastContainer />
        </div>
        
            
        
    );
};

export default Dashbord;