import { GetAxiosInstance } from "./Api"

export const ApiOpenOrderDetail = (userToken,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get(`Order/OpenOrderDetail`,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiProductOrder = (userTokne,callback) =>{
    const config = {
        headers: { Authorization: `Bearer ${userTokne}` }
    };
    GetAxiosInstance().get(`Order/ProductOrder`,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiAddToOrder = (userToken,Order,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Order/AddToOrder`,Order,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err);
    }) 
}

export const ApiDeleteProductFromOrder = (userToken,ItemId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().delete(`Order/DeleteProductFromOrder/${ItemId}`,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiIncreasItemOrder = (userToken,ItemId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Order/IncreaseItemOrder/${ItemId}`,ItemId,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiDecreasItemOrder = (userToken,ItemId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Order/DecreaseItemOrder/${ItemId}`,ItemId,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiGetShippingMethodSeller = (userToken,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get(`Order/GetShippingMethodSeller`,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiSelectAddressOrder = (userToken,AddressId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Order/SelectAddressOrder/?addressId=${AddressId}`,AddressId,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}

export const ApiSelectShippingMethodOrder = (userToken,ShippingId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`Order/SelectShippingMethodOrder?shippingId=${ShippingId}`,ShippingId,config).then(res => {    
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    }) 
}


export const ApiPaymentOrder = (userToken,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get("Order/PaymentOrder",config)
        .then(respons => 
            {
                localStorage.setItem('orderId', respons.data[1]);
                window.location.assign("https://sandbox.zarinpal.com/pg/StartPay/" + respons.data[0]);
            }).catch(err => {
                callback(false , err.message);
            });
}

export const ApiCallBackPayment = (userToken,OrderAuthority,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post("Order/CallBackPayment" ,OrderAuthority,config)
        .then(respons => 
            {
                callback(true , respons.data);
            }).catch(err => {
                callback(false , err.message);
            });
}

export const ApiPaidOrders = (userToken,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get("Order/PaidOrder",config)
        .then(respons => 
            {
                callback(true , respons.data);
            }).catch(err => {
                callback(false , err.message);
            });
}


export const ApiPaidOrderDetail = (userToken,orderId,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get(`/Order/PaidOrderDetail?orderId=${orderId}`,config)
        .then(respons => 
            {
                callback(true , respons.data);
            }).catch(err => {
                callback(false , err.message);
            });
}