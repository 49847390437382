import { GetAxiosInstance } from "./Api"



export const ApiUserInfo = (userToken , callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get("/User/UserInfo", config).then(res => 
        {
            callback(true , res.data);
        }).catch(err => {
            callback(false , err.message);
        })
}; 
export const ApiEditProfile = (userToken,FirstName,LastName,Image , callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post(`User/EditProfile?FirstName=${FirstName}&LastName=${LastName}`,Image, config).then(res => 
        {
            callback(true , res.data);
        }).catch(err => {
            callback(false , err.message);
        })
}; 
export const ApiChangePassword = (userToken , ChangePassword , callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().post("/User/ChangePassword",ChangePassword ,config).then(res => 
        {
            callback(true , res.data);
        }).catch(err => {
            callback(false , err.message);
        })
}; 
export const ApiUserAddressList = (userToken , callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userToken}` }
    };
    GetAxiosInstance().get("/User/UserAddressList", config).then(res => 
        {
            callback(true , res.data);
        }).catch(err => {
            callback(false , err.message);
        })
}; 