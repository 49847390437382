import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { SliderOrigin } from '../../Utils/PathExtention';

const Slide = ({key,slider}) => {
    return (
        <div className="carousel-item active">
        <img src={SliderOrigin + slider.imageName} className="d-block w-100" alt="..." />
        <div className="carousel-caption d-none d-md-block">
          <h5>First slide label</h5>
          <p>Some representative placeholder content for the first slide.</p>
        </div>
      </div>
    );
};

export default Slide;