import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css' ;
import 'bootstrap/dist/js/bootstrap.bundle';
import styles from './faq.module.css'
import { ApiGetFAQ } from '../../Api/ApiPost';
import { useState } from 'react';
import FaqItem from './FaqItem';
const Faq = () => {
    const [faqs,setFaqs] = useState([]);
    useEffect(() => {
      ApiGetFAQ((IsOk,response)=>{
          if(IsOk){
            setFaqs(response);
          }else{
            console.log(response);
          }
      });
    },[])
    
    return (
        <div className={styles.container}>
            <div class="accordion" id="accordionExample">
              {
                  faqs?.map(item => <FaqItem key={item.postId} Item={item} />)
              }
     
    </div>
        </div>
       
    );
};

export default Faq;