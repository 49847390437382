import React from 'react';
import loadimage from '../images/372.gif'
import style from './pageloading.module.css'
const Pageloading = () => {
    return (
        <div className={style.continer}>
            <img src={loadimage} style={{ width: 64,height : 64  }}  />
        </div>
    );
};

export default Pageloading;