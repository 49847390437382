import React from 'react';
import 'bootstrap/dist/css/bootstrap.css' ;
import 'bootstrap/dist/js/bootstrap.bundle';


const FaqItem = ({Item}) => {
    return (
        <div class="accordion-item">
        <h2 class="accordion-header" id={"heading" + Item.postId}>
          <button class="accordion-button test" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + Item.postId}
            aria-expanded="true" aria-controls={"collapse" + Item.postId}>
            {Item.title}
          </button>
        </h2>
        <div id={"collapse" + Item.postId} class="accordion-collapse collapse" aria-labelledby={"heading" + Item.postId}
          data-bs-parent="#accordionExample">
          <div contentEditable='true' dangerouslySetInnerHTML={{ __html: Item.body }} class="accordion-body">
          </div>
        </div>
      </div>
    );
};

export default FaqItem;