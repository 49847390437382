import React from 'react';
import styles from './checkout.module.css';
const CheckoutProductOrder = ({productOrder}) => {
    return (

            


            
          
                <tr>
                    <td className={styles.tdcheckouttitle}><label>{productOrder.title} </label></td>
                    <td className={styles.tdcheckout}><label>{productOrder.count}</label></td>
                    <td className={styles.tdcheckout}><label>{productOrder.price + productOrder.productDifferencPrice}</label></td>
                </tr>
           
            
                
                       
                
                
            
       
            

            



    );
};

export default CheckoutProductOrder;