import { GetAxiosInstance } from "./Api"



export const ApiLogin = (loginData , callback) => {
    GetAxiosInstance().post("/Auth/login", loginData).then(res => 
        {
            callback(true , res.data);
        }).catch(err => {
            callback(false , err.message);
        })
}; 
export const ApiRegister =(registerData,callback) =>{
    GetAxiosInstance().post("/Auth/register",registerData).then(res =>{
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    })
};
export const ApiActiveMobile =(activeMobileData,callback) =>{
    GetAxiosInstance().post("/Auth/activatemobile",activeMobileData).then(res =>{
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    })
};
export const ApiForgetPassword =(forgetMobileData,callback) =>{
    GetAxiosInstance().post("Auth/forget-password",forgetMobileData).then(res =>{
        callback(true,res.data);
    }).catch(err=>{
        callback(false,err.message);
    })
}; 
export const ApiIsLogin = (userTokne,callback) => {
    const config = {
        headers: { Authorization: `Bearer ${userTokne}` }
    };
    GetAxiosInstance().get("/Auth/Islogin",config).then(res => 
        {
            callback(true , res.data);
        }).catch(err => {
            callback(false , err.message);
        })
};
// export const TestApi = (usertoken ,callback) => {
//     GetAxiosInstance().get("/WeatherForecast", {headers : {"Authorization" : `Bearer ${usertoken} `}}).then(res => 
//         {
//             callback(true , res.data);
//         }).catch(err => {
//             callback(false , err);
//         })
// }