import React, { createContext, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiIsLogin } from '../Api/ApiAuth';
import { ApiUserInfo } from '../Api/ApiUser';
export const UserContext=createContext();

const UserInfo = ({children}) => {
    const [Email,setEmail] = useState(" ");
    const [FirstName,setFirstName] = useState(" ");
    const [LastName,setLastName] = useState(" ");
    const [PhoneNumber,setPhoneNumber] = useState(" ");
    const [UserToken,setUserToken] = useState(" ");
    const [IsLogin,setIsLogin] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        var userToken = localStorage.getItem('userToken');
        setEmail("")
        setFirstName("")
        setLastName("")
        setPhoneNumber("")
        if(userToken != null){
            ApiIsLogin(userToken,(IsOk) => 
            {
                setIsLogin(IsOk);
                if(IsOk){
                    ApiUserInfo(userToken,(IsOk,response) => {
                        if(IsOk){
                            localStorage.setItem('userEmail', response.email);
                            localStorage.setItem('userFirstName', response.firstName);
                            localStorage.setItem('userLastName', response.lastName);
                            localStorage.setItem('userPhoneNumber', response.phoneNumber);
                            localStorage.setItem('userAvatar',response.avatar);
                        }
                    })
                    
    
                  
                }else{
                    localStorage.setItem('userEmail', "");
                    localStorage.setItem('userFirstName', "");
                    localStorage.setItem('userLastName', "");
                    localStorage.setItem('userPhoneNumber', "");
                    localStorage.setItem('userToken', "");
                    localStorage.setItem('IsLogin', false);
                }
                
            });         
        }
        else{
            localStorage.setItem('userEmail', "");
            localStorage.setItem('userFirstName', "");
            localStorage.setItem('userLastName', "");
            localStorage.setItem('userPhoneNumber', "");
            localStorage.setItem('userToken', "");
            localStorage.setItem('IsLogin', false);
        }
       
       
       
    },[])

    const UserLogout = () => {
        setEmail("")
        setFirstName("")
        setLastName("")
        setPhoneNumber("")
        setUserToken("")
        setIsLogin(false)
        localStorage.setItem('userEmail', "");
        localStorage.setItem('userFirstName', "");
        localStorage.setItem('userLastName', "");
        localStorage.setItem('userPhoneNumber', "");
        localStorage.setItem('userToken', "");
        localStorage.setItem('IsLogin', false);
        navigate("/");
    }   
    return (
            <UserContext.Provider value={{UserToken,setUserToken,PhoneNumber,setPhoneNumber,Email,setEmail,FirstName,setFirstName,LastName,setLastName,IsLogin,setIsLogin,UserLogout}}>
                {children}
            </UserContext.Provider>
    );
};

export default UserInfo;
