import React from 'react';
import style from './category.module.css'
import icongategory from '../../images/—Pngtree—vector gear icon_3989668.png'
const Category = ({ProductFilterCategory,selectedCategoryUrl,category}) => {
    const ProductFilterCategoryHandeler = () => 
    {
        ProductFilterCategory(category.urlName); 
        console.log(category)
    }
    return (
       
        <div className={`${style.container} row`}>
            <div onClick={ProductFilterCategoryHandeler} className={category.urlName===selectedCategoryUrl ? style.sectionselected : style.section}>
                
                    <span><img src={icongategory} width="50px" height="50px"/></span>
                
                <div className={style.namecategory} >
                    <span>{category.title}</span>
                </div>
            </div>
        </div>
        
    );
};

export default Category;