// import Footer from "./Component/Footer";
import Landing from "./Component/Landing";
import Navbar from "./Component/Navbar";
// import Products  from './Component/Products';
import Auth from './Component/Auth/Auth';
import UserInfo from './Context/UserInfo';
import {Route , Routes} from 'react-router-dom';
//import Search from "./Component/Search";
import ProductProvider from "./Context/ProductProvider";
import ProductDetail from "./Component/Products/ProductDetail";
import Faq from "./Component/Post/Faq";
import OrderProvider from "./Context/OrderProvider";
import Checkout from "./Component/Order/Chechout/Checkout";
import Contact from "./Component/Contact";
import CallbackPayment from "./Component/Order/Chechout/CallbackPayment";
import PageLoaderProvider from "./Context/PageLoaderContext";
import Dashbord from "./Component/UserPanel/Dashbord";
import UserOrders from "./Component/UserPanel/UserOrders/UserOrders";
import ChangePassword from "./Component/UserPanel/ChangePassword";
import WishList from "./Component/UserPanel/WishList/WishList";
// import OrderSidbar from "./Component/OrderSidbar";
// import UserPanel from "./Component/UserPanel/UserPanel";
// import UserOrder from "./Component/UserPanel/UserOrder/UserOrder";
// import EditPassword from "./Component/UserPanel/EditPassword/EditPassword";

function App() {
  return (
    <div>
      <PageLoaderProvider>
      <UserInfo>
    
      <ProductProvider>
        <OrderProvider>
      <Navbar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}/>
        <Routes>
            <Route path="/" element={<Landing/>}/>
            <Route path="/product/:id" element={<ProductDetail/>}/>
            <Route path="/auth" element={<Auth/>}/>
            <Route path="/faq" element={<Faq/>}/>
            <Route path="/contact" element={<Contact/>}/> 
            <Route path="/checkout" element={<Checkout/>}/>
            <Route path="/CallBackPayment" element={<CallbackPayment/>} />
            <Route path="/userpanel" element={<Dashbord />} />    
            <Route path="/userpanel/orders" element={<UserOrders/>}/>
            <Route path="/userpanel/changepassword" element={<ChangePassword/>}/>
            <Route path="/userpanel/wishlist" element={<WishList/>} />
            {/* <Route path="/userpanel/editpassword" element={<EditPassword />}/>
            <Route path="/userpanel/userorder" element={<UserOrder />}/>
            <Route path="/userpanel" element={<UserPanel />}/> */}
        </Routes>

        </OrderProvider>
      </ProductProvider>
      </UserInfo>
      </PageLoaderProvider>
    </div>
  );
}

export default App;
