import React, { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react'
import { toast } from 'react-toastify';
import { ApiDecreasItemOrder, ApiIncreasItemOrder, ApiOpenOrderDetail, ApiProductOrder } from '../Api/ApiOrder';



export const OrderContext = createContext();
const OrderProvider = ({children}) => {
    const [isopen,setIsopen] = useState(false);
    const [isclose,setIsclose ] = useState(true);
    const [productOrder,setProductOrder] = useState([]);
    const [orderDetail,setOrderDetail] = useState();
    useEffect(()=>{
        ApiProductOrder(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                setProductOrder(response);
                
            }
            else{
                console.log(response);
            }
        });
        ApiOpenOrderDetail(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk){
                setOrderDetail(response);
            }else{
                console.log(response);
            }
        });
     
    },[])
    const EmptyOrder = () => {
        setProductOrder([]);
    }
    const ReloadApiProductOrder = () => {
        ApiProductOrder(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk)
            {
                setProductOrder(response);
                
            }
            else{
                console.log(response);
            }
        });
        ApiOpenOrderDetail(localStorage.getItem('userToken'),(IsOk,response) => 
        {
            if(IsOk){
                setOrderDetail(response);
            }else{
                console.log(response);
            }
        });
    }
    const IncreasItemOrder = (ItemId) => {
        ApiIncreasItemOrder(localStorage.getItem('userToken'),ItemId,(IsOk,response) => {
            if(IsOk){
                ApiProductOrder(localStorage.getItem('userToken'),(IsOk,response) => 
                {
                    if(IsOk)
                    {
                        setProductOrder(response);
                        
                    }
                    else{
                        console.log(response);
                    }
                });
            }else{
                toast.error(response , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }
    const DecreasItemOrder = (ItemId) => {
        ApiDecreasItemOrder(localStorage.getItem('userToken'),ItemId,(IsOk,response) => {
            if(IsOk){
              
                ApiProductOrder(localStorage.getItem('userToken'),(IsOk,response) => 
                {
                    if(IsOk)
                    {
                        setProductOrder(response);
                        
                    }
                    else{
                        console.log(response);
                    }
                });
            }else{
              
            }
        })
    } 
    return (
        <OrderContext.Provider value={{isopen,isclose,setIsopen,setIsclose,productOrder,orderDetail,IncreasItemOrder,DecreasItemOrder,ReloadApiProductOrder,EmptyOrder}}>
        {children}
        </OrderContext.Provider>
    );
};

export default OrderProvider;