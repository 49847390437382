import React, { useState , useContext } from 'react';
import styles from "./Navbar.module.css"
// import logo from '../images/Logo.png';
// import { slide as Menu } from 'react-burger-menu'
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faHeart, faPhone, faQuestion, faSearch, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css';
// import MenuButton from 'react-burger-menu'
import { faDashboard} from '@fortawesome/free-solid-svg-icons';
import { faUser} from '@fortawesome/free-solid-svg-icons';
import { faReorder} from '@fortawesome/free-solid-svg-icons';
import { faLock} from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
// import { faShop } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../Context/UserInfo';
import { OrderContext } from '../Context/OrderProvider';
import { ProductContext } from '../Context/ProductProvider';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import CategoriesMobile from './Categories/CategoriesMobile';
const Navbar = () => {
    const productcontext = useContext(ProductContext);
    const orderconfig= useContext(OrderContext);
    const userContext = useContext(UserContext);
    const [isOpen,setIsOpen] = useState(false) 
    const [iOpenDashbord,setIopenDashbord] = useState(false)
    const [openSearch,setOpenSearch]=useState(false)
    const [isOpencategory,setIsopencategory]=useState(false);
    const [productSearch,setProductSearch] = useState("");
    const navigate = useNavigate();
    function openHandler(){
        setIsOpen(true)
        orderconfig.setIsopen(false)
        orderconfig.setIsclose (true)
    }
    function openHandlerCategory(){
        if(isOpencategory){
            setIsopencategory(false)
        }else{
            setIsopencategory(true)
        }
        
    }
  
    function openHandlerDashbord(){
        setIopenDashbord(true)
        orderconfig.setIsopen(false)
        orderconfig.setIsclose (true)
    }
    function closeHandeler(){
        setIsOpen(false)
        setIsopencategory(false)
        setIopenDashbord(false)
    }
    function closeHandelerDashbord(){
        setIopenDashbord(false)
    }
    function searchHandler(){
       if (openSearch){
        setOpenSearch(false)
       }else{
        setOpenSearch(true)
       }
    }
    const onChangeProductSearch = (event) => {
        setProductSearch(event.target.value);
    }

    const ProductFilterTitle = () => {
        
    }
  

    const orderopenhandler = () => {
        orderconfig.setIsopen(true)
        orderconfig.setIsclose (false)
        console.log(orderconfig.isopen)
        console.log(orderconfig.isclose)

    }

    const logoutUser = () => {
        orderconfig.EmptyOrder();
        userContext.UserLogout();
        setIopenDashbord(false)
   
    }
    

   
    return (
        <div>
        <header className={styles.header}>
            <div className={styles.listcontainer}>
                <ul className={styles.list}>
                <li><Link to='/'>صفحه اصلی  </Link></li>
                {/* <li><Link to='/product'>محصولات </Link></li> */}
                <li><Link to='/contact'>ارتباط با ما</Link></li>
                <li><Link to='/faq'>سوالات متداول</Link></li>
                <li><Link to='/auth'>برند</Link></li>
                {/* <li><Link to='/auth'><button className={styles.signup}>ثبت نام</button></Link></li>
                <li><Link to='/auth'><button className={styles.signin}>ورود</button></Link></li> */}
                {userContext.IsLogin ?  <li><button onClick={logoutUser} className={styles.signup}>خروج</button></li> : 
                 <li><Link to='/auth'><button className={styles.signup}>ورود</button></Link></li>
                //  <li><Link to='/auth'><button className={styles.signin}>ورود</button></Link></li>
                }
                {
                    userContext.IsLogin ? <Link to="/userpanel"><li ><span><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></span>پروفایل</li></Link> : 
                    <></>
                }
                </ul>
            </div>
            <div className={styles.searchbox} >
                <div className={styles.formsearckbox}>
                    <input onChange={onChangeProductSearch} type='text' placeholder='جست و جو...'></input>
                    <button onClick={() => productcontext.ProductFilterTitle(productSearch)} ><FontAwesomeIcon icon={faSearch} /></button>
                </div>
            </div>
            <div className={styles.logoimage}>
                {/* <img  className={styles.logoimage} src={logo} alt="Logo"/> */}
                <i>React</i><i>store </i>
                <div className={`${styles.mobilesearch} ${openSearch ?  styles.displayblock:styles.displaynone }`} >
                <div className={styles.formsearckbox}>
                    <input onChange={onChangeProductSearch} type='text' placeholder='جست و جو...'></input>
                    <button onClick={() => productcontext.ProductFilterTitle(productSearch)} ><FontAwesomeIcon icon={faSearch} /></button>
                </div>
            </div>
            </div>

        </header>
          
          
        {/* <FontAwesomeIcon icon={faShop}/> */}
          {/* menu mobile */}
        <div className={styles.mobilenav}>
        {!userContext.IsLogin ?<Link className="menu-item" to="/auth"><p><FontAwesomeIcon icon={faSignIn}/></p></Link>:<p onClick={openHandlerDashbord}><FontAwesomeIcon icon={faUser}  /></p>} 
        <p onClick={searchHandler}><FontAwesomeIcon icon={faMagnifyingGlass }/></p>
        <p onClick={orderopenhandler}> <svg width="18" height="18" class="shrink-0" viewBox="0 0 12.686 16"><g transform="translate(-27.023 -2)"><g transform="translate(27.023 5.156)"><g><path d="M65.7,111.043l-.714-9A1.125,1.125,0,0,0,63.871,101H62.459V103.1a.469.469,0,1,1-.937,0V101H57.211V103.1a.469.469,0,1,1-.937,0V101H54.862a1.125,1.125,0,0,0-1.117,1.033l-.715,9.006a2.605,2.605,0,0,0,2.6,2.8H63.1a2.605,2.605,0,0,0,2.6-2.806Zm-4.224-4.585-2.424,2.424a.468.468,0,0,1-.663,0l-1.136-1.136a.469.469,0,0,1,.663-.663l.8.8,2.092-2.092a.469.469,0,1,1,.663.663Z" transform="translate(-53.023 -101.005)" fill="currentColor"></path></g></g><g transform="translate(30.274 2)"><g><path d="M160.132,0a3.1,3.1,0,0,0-3.093,3.093v.063h.937V3.093a2.155,2.155,0,1,1,4.311,0v.063h.937V3.093A3.1,3.1,0,0,0,160.132,0Z" transform="translate(-157.039)" fill="currentColor"></path></g></g></g></svg></p>
        <Link className="menu-item" to="/"><p><FontAwesomeIcon icon={faHome}/></p></Link>
        <p onClick={openHandler}><FontAwesomeIcon icon={faBars }/></p> 
        </div>
        <div className="hamburgermenu">
               <Menu customBurgerIcon={false} right  isOpen ={ isOpen } onClose={closeHandeler}>
                <Link onClick={closeHandeler} className="menu-item" to="/contact"><div className="div"><span><FontAwesomeIcon  icon={faPhone}/></span>ارتباط با ما</div></Link>
                <Link onClick={closeHandeler} className="menu-item" to="/faq"><div className="div"><span><FontAwesomeIcon  icon={faQuestion}/></span>سوالات متداول</div></Link>
                <Link onClick={closeHandeler} className="menu-item" to="/auth"><div className="div"><span><FontAwesomeIcon  icon={faSearch}/></span>برند</div></Link>
                
                <Link className={`menu-item`} onClick={openHandlerCategory}><div className={`div `}><span><FontAwesomeIcon icon={faSearch}/></span>دسته بندی</div>
                    <div className={isOpencategory?!styles.dropdown:styles.categorydropdown} onClick={closeHandeler}>
                        <CategoriesMobile/>
                    </div>
                </Link>
                
                {/* <Link className="menu-item" to="/editpassword"><div className="div"><span><FontAwesomeIcon icon={faLock}/></span>تغییر رمز</div></Link> */}
            </Menu>
        </div>
        <div dir="ltr" className="hamburgermenu">
            <Menu customBurgerIcon={false} left   isOpen ={ iOpenDashbord } onClose={closeHandelerDashbord}>
                    <Link onClick={closeHandeler} className="menu-item" to="/userpanel"><div className="div"><span><FontAwesomeIcon  icon={faHome}/></span>داشبورد</div></Link>
                    <Link onClick={closeHandeler} className="menu-item" to="/userpanel/changepassword"><div className="div"><span><FontAwesomeIcon icon={faLock}/></span>تغییر رمز</div></Link>
                    <Link onClick={closeHandeler} className="menu-item" to="/userpanel/orders"><div className="div"><span><FontAwesomeIcon icon={faReorder}/></span>سفارشات</div></Link>
                    <Link onClick={closeHandeler} className="menu-item" to="/userpanel/wishlist"><div className="div"><span><FontAwesomeIcon icon={faHeart}/></span>علاقه مندی ها</div></Link>
                    <Link onClick={closeHandeler} className="menu-item" ><div className="div"><span onClick={logoutUser} ><FontAwesomeIcon icon={faSignOut}/>خروج</span></div></Link>
            </Menu>

        </div>
      
{/* 
        <Menu >
<a id="home" className="menu-item" href="/">Home</a>
<a id="about" className="menu-item" href="/about">About</a>
<a id="contact" className="menu-item" href="/contact">Contact</a>
<a className="menu-item--small" href="">Settings</a>
</Menu>  */}
      
    
        </div>
    );
};

export default Navbar;




