import Config from "../Configuration.json";


export const SliderOrigin = `${Config.SERVER_ADMINPANEL}/content/images/Slider/origin/`;
export const SliderThumbnailImage = `${Config.SERVER_ADMINPANEL}/content/images/Slider/thumb/`;
export const ProductImage = `${Config.SERVER_SELLERPANEL_URL}/content/images/product/origin/`;
export const ProductThumbnailImage = `${Config.SERVER_SELLERPANEL_URL}/content/images/product/thumb/`;
export const ProductGalleryImage = `${Config.SERVER_SELLERPANEL_URL}/content/images/product-gallery/origin/`;
export const ProductGalleryThumbnailImage = `${Config.SERVER_SELLERPANEL_URL}/content/images/product-gallery/thumb/`;
export const UserAvatarOrigin = `${Config.SERVER_URL}/Content/Images/UserAvatar/origin/`;
export const UserAvatarThumb = `${Config.SERVER_URL}/Content/Images/UserAvatar/Thumb/`;

