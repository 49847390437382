import React from 'react';
import style from './product.module.css'
// import picture from '../../images/apple.jpg' 
import { ProductThumbnailImage } from '../../Utils/PathExtention';
import { Link } from 'react-router-dom';
import {  ApiAddProductToFavoriteList } from '../../Api/ApiProducts';
import { toast } from 'react-toastify';
import { faHeart} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Product = ({product}) => {
    const AddProductToFavoriteList = () => {
        ApiAddProductToFavoriteList(localStorage.getItem("userToken"),product.productId,(IsOk,response) => 
        {
            if(IsOk)
            {
                switch (response.statusCode) {
                    case 501:
                        toast.error(response.errorMessage, {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 500:
                        toast.error(response.errorMessage, {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    case 200:
                        toast.success(response.successMessage, {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                    default:
                        break;
                }
            }
            else{
                toast.error('لطفا اول لاگین کنید', {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }
    return (
     
       <div className={`${style.container} col-sm-12  col-md-6 col-lg-3 col-xl-4 col-xxl-3 `}> 
                <div  className={`${style.cart} `}>
                    <Link to={`/product/${product.productId}`} >
                        <div className={style.bodycart}>
                            <img src={ProductThumbnailImage + product.imageName} alt="..." />
                            <p>{product.title}</p>
                            <span className={style.cartprice}>{`${product.price.toLocaleString()} تومان`}</span><br/>
                            {/* <button className={style.addtocart}>مشاهده محصول</button> */}
                        </div>
                    </Link>
                    <div className={style.footercart}>
                        <span className={style.favorit} onClick={AddProductToFavoriteList}><FontAwesomeIcon icon={faHeart} /></span>
                        <Link to={`/product/${product.productId}`}><button className={style.addtocart}>مشاهده محصول</button></Link>
                        <div className={style.rating}>
                            <span className={style.numberrating}>4</span>
                            <span className={style.ratingsvg}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.056 24" class="h-2.5 w-2.5 ltr:ml-1 rtl:mr-1"><g data-name="Group 36413" fill="currentColor"><path id="Path_22667" data-name="Path 22667" d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z" transform="translate(0 -10.792)"></path></g></svg></span>
                        </div>
                    </div>
                </div>
       
       </div>
                


        
    );
};

export default Product;