import React, { useEffect } from 'react';
import style from './contact.module.css'
import imagecontact from '../images/contact-illustration.2f6adc05.svg'
import { useState } from 'react';
import {  ApiCreateContactUs, ApiSiteSetting } from '../Api/ApiCommon';
import { toast, ToastContainer } from 'react-toastify';
const Contact = () => {
    const [siteSetting,setSiteSetting] = useState({});
    const [fullName,setFullName] = useState("");
    const [Email,setEmail] = useState("");
    const [subject,setSubject] = useState("");
    const [description,setDescription] = useState("");
    useEffect(()=>{

        ApiSiteSetting((IsOk,response) => 
        {
            if(IsOk)
            {
                setSiteSetting(response);
                
            }
            else{
                console.log(response);
            }
        });
  
    },[])
    const onChangeFullNameHandeler = (event) => {
        setFullName(event.target.value);
    }
    const onChangeEmailHandeler = (event) => {
        setEmail(event.target.value);
    }
    const onChangeSubjectHandeler = (event) => {
        setSubject(event.target.value)
    }
    const onChangeDescriptionHandeler = (event) => {
        setDescription(event.target.value)
    }
    const CreateContactUs = () => {
        const ContactUsData = {
            Captcha : "test",
            Email : Email,
            FullName : fullName,
            Subject : subject,
            Text : description
        }

        ApiCreateContactUs(localStorage.getItem('userToken'),ContactUsData,(IsOk,response) => {
            if(IsOk){
                toast.success('نظر شما ارسال شد' , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }else{
                toast.error(response.ErrorMessage , {
                    position: "bottom-right",
                    closeButton: true,
                    closeOnClick: true,
                  });
            }
        });
    }
    return (
        <div className={`${style.container} container-fluid row`}>
            <div className={`${style.rightbox} col-sm-12  col-md-12 col-lg-12 col-xl-6  col-xxl-6 offset-xxl-1`}>
                <h1>با ما در ارتباط باشید </h1>
                <div className={style.onesection}>
                    <div className={style.firstnameinput}><lable>نام</lable><input onChange={onChangeFullNameHandeler} type="text"/></div>
                    <div className={style.emailinput}><lable>ایمیل</lable><input onChange={onChangeEmailHandeler} type="email"/></div>
                </div>
                <div className={style.twosection}>
                    <div className={style.subjectinput}><lable>عنوان</lable><input onChange={onChangeSubjectHandeler} type="text"/></div>
                </div>
                <div className={style.thirdsection}>
                <div className={style.description}><lable>توضیحات</lable><textarea onChange={onChangeDescriptionHandeler} ></textarea></div>
                </div>
                <button  onClick={CreateContactUs} className={style.sendmsg}>ارسال نظر</button>
            </div>

            <div className={`${style.leftbox} col-sm-12  col-md-12 col-lg-12 col-xl-3 col-xxl-3 `}>
                <div className={style.imgsection}><img src={imagecontact}/></div>
                <div className={style.address}><span className={style.onespan}>آدرس</span><span className={style.twospan}>{siteSetting.address}</span></div>
                <div className={style.phone}><span className={style.onespan}>تلفن تماس</span><span className={style.twospan}>{siteSetting.phone}</span></div>
                <div className={style.socialmedia}><span className={style.onespan}>ما را دمبال کنید</span><span className={style.twospan}></span></div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Contact;