import React from 'react';
import style from './wishlist.module.css'
import img from '../../../images/apple.jpg'
import UserpanelNavbar from '../UserpanelNavbar';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiDeleteProductFromFavoriteList, ApiProductFavoriteList } from '../../../Api/ApiProducts';
import { ProductThumbnailImage } from '../../../Utils/PathExtention';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
const WishList = () => {
    const [wishList,setWishList] = useState({});
    useEffect(() => {
        ApiProductFavoriteList(localStorage.getItem("userToken"),(IsOk,response) => 
        {
            if(IsOk)
            {
               setWishList(response);
            }
            else{
               console.log(response);
            }
        });
    }, [])

    const DeleteProductFromFavoriteList = (productId) => {
        ApiDeleteProductFromFavoriteList(localStorage.getItem("userToken"),productId,(IsOk,response) => 
        {
            if(IsOk)
            {
                switch (response.statusCode) {
                    case 404:
                        toast.error(response.errorMessage, {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                        break;
                   
                    case 200:
                        toast.success(response.successMessage, {
                            position: "bottom-right",
                            closeButton: true,
                            closeOnClick: true,
                          });
                          ApiProductFavoriteList(localStorage.getItem("userToken"),(IsOk,response) => 
                          {
                              if(IsOk)
                              {
                                 setWishList(response);
                              }
                              else{
                                 console.log(response);
                              }
                          });
                        break;
                    default:
                        break;
                }
            }
            else{
               console.log(response);
            }
        });
    }
    
    return (
        <div className={style.container}>
            <UserpanelNavbar/>
            <div className={style.widhlist}>
                <div className={style.title}><h3>لیست علاقه مندی ها</h3></div>
                {
                    wishList?.productLike?.map(wish=>{
                        return(
                            <>
                                <div className={style.wish}>
                            <div className={style.imagewishlist}>
                                <span><img src={ProductThumbnailImage + wish.imageName}/></span>
                            </div>
                            <div className={style.wishdetial}>
                                <div className={style.datawishlist}>
                                    <p>{wish.title}</p>
                                    <span className={style.rating}>
                                        <span className={style.numberrating}>{wish.rated}</span>
                                        <span className={style.ratingsvg}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.056 24" class="h-2.5 w-2.5 ltr:ml-1 rtl:mr-1"><g data-name="Group 36413" fill="currentColor"><path id="Path_22667" data-name="Path 22667" d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z" transform="translate(0 -10.792)"></path></g></svg>
                                        </span>
                                    </span>
                                    
                                </div>
                                <div className={style.btnwishlist}>
                                    <span>{wish.price} تومان</span>
                                    <div>
                                    <Link to={`/product/${wish.productId}`}>

                                        <button className={style.visit}><span>مشاهده محصول</span></button>
                                        </Link>
                                        <button onClick={() => DeleteProductFromFavoriteList(wish.productId)} className={style.remove}><span>حذف محصول</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                            </>
                        
                        )
                    })
                }
                
             
               
                
            </div>
            <ToastContainer />
         
        </div>
    );
};

export default WishList;