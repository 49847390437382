import React, {useState} from 'react';
import styles from './signin.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser ,faKey} from '@fortawesome/free-solid-svg-icons'
import { ApiActiveMobile, ApiRegister } from '../../Api/ApiAuth';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../Context/UserInfo';
import { OrderContext } from '../../Context/OrderProvider';

const Signin = () => {
    const navigate = useNavigate(); 
    const UserInfo = useContext(UserContext);
    const OrderConfig = useContext(OrderContext);

    const [firstName,setFirstName] = useState("");
    const [lastName,setlastName] = useState("");
    const [phoneNumber,setphoneNumber] =useState("");
    const [password,setPassword] = useState("");
    const [repassword,setrePassword] = useState("");
    const [sendActiveCode,setSendActiveCode] =useState(false);
    const [activeCode,setActiveCode] = useState("");
    const Register = () => {
        const data={
            Captcha: "default",
            Mobile: phoneNumber,
            Email: "sss@gmail.com",
            FirstName: firstName,
            LastName: lastName,
            Password: password,
            ConfirmPassword: repassword
        }

        ApiRegister(data,(IsOk,response)=> 
        {
            if(IsOk)
            {
                if(response.statusCode == 409){
                    toast.error('شماره موبایل قبلا وارد شده است' , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                if(response.statusCode == 400){
                    toast.error( 'لطفا همه فیلدها را پر کنید', {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                if(response.statusCode == 201){
                    toast.success( 'حساب کاربری با موفقیت ایجاد شد ', {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                      toast.info( 'کد فعال سازی برای شما ارسال شد', {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                      setTimeout(function() {
                        setSendActiveCode(true);
                        }, 5000);
                   
                }

            }
            else{
                console.log(response);
            }
        });
    }

    const ActiveMobile = () => {
        const activeMobileData = 
        {
            Captcha : "test",
            Mobile : phoneNumber,
            MobileActiveCode : activeCode
        }
        
        ApiActiveMobile(activeMobileData,(IsOk,response)=> 
        {
            if(IsOk)
            {
                if(response.statusCode == 404){
                    toast.error('حساب کاربری شما یافت نشد' , {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                if(response.statusCode == 400){
                    toast.error( 'لطفا همه فیلدها را پر کنید', {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                }
                if(response.statusCode == 201){
                    UserInfo.setUserToken(response.token);
                    localStorage.setItem('userToken', response.token);
                    UserInfo.setPhoneNumber(phoneNumber);
                    localStorage.setItem('userPhoneNumber',phoneNumber);
                    UserInfo.setIsLogin(true);
                    localStorage.setItem('IsLogin',true);
                    toast.success( 'حساب کاربری شما با موفقیت فعال شد', {
                        position: "bottom-right",
                        closeButton: true,
                        closeOnClick: true,
                      });
                    OrderConfig.ReloadApiProductOrder();
                    setTimeout(function() {
                    navigate("/");
                    }, 3000);
                   
                      
                }

            }
            else{
                console.log(response);
            }
        });
    }
        return (
            !sendActiveCode ? 
            <>
            <div className={styles.container}>
                <h3>ساخت حساب کاربری</h3>
                <div>
                    <span><FontAwesomeIcon icon={faUser} /></span>
                    <input type="text" onChange={(e) => setFirstName(e.target.value)} placeholder="نام" name="name"/>
                    <span><FontAwesomeIcon icon={faUser} /></span>
                    <input type="text" onChange={(e) => setlastName(e.target.value)} placeholder="نام خانوادگی" name="name"/>
                </div>
                {/* <div>
                    <span><FontAwesomeIcon icon={faUser} /></span>
                    <input type="text" placeholder="LastName" name="name"/>
                </div> */}
                <div>
                <span><FontAwesomeIcon icon={faKey } /></span>
                    <input type="tel" onChange={(e) => setphoneNumber(e.target.value)}  placeholder="شماره همراه" name="phonenumber"/>
                </div>
                <div>
                <span><FontAwesomeIcon icon={faKey} /></span>
                    <input type="password" placeholder="رمز عبور" onChange={(e) => setPassword(e.target.value)} name="password"/>
                    <span><FontAwesomeIcon icon={faKey} /></span>
                    <input type="password" placeholder="تکرار رمز عبور" onChange={(e) => setrePassword(e.target.value)} name="password"/>
                </div>
                
                <button className={`${styles.btn}`} onClick={Register}>ثبت نام</button>
            </div>
            <ToastContainer />
 
            </> : <div><div className={styles.container}><h3>ارسال کد فعال سازی</h3><br/><br/>  <div><input onChange={(e) => setActiveCode(e.target.value)}  type="password" placeholder="کد فعالسازی" name="password"/></div><button className={`${styles.btn}`} onClick={ActiveMobile}>تایید</button></div><ToastContainer /></div>

           
        );
}

export default Signin;