import React from 'react';
import style from './categorymobile.module.css';
// import styleCategory from './category.module.css';
import icongategory from '../../images/—Pngtree—vector gear icon_3989668.png'
import imgcat from '../../images/apple.jpg'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiCategory } from '../../Api/ApiProducts';
import Category from './Category';
import { ProductContext } from '../../Context/ProductProvider';
import { PageLoaderContext } from '../../Context/PageLoaderContext';
import CategoryMobile from './CategoryMobile';
const CategoriesMobile = () => {
    const PageLoader = useContext(PageLoaderContext);
    const ProductsContext = useContext(ProductContext);
    const [categories,setCategories] = useState([])
    useEffect(()=>{
        let pageLoaderCounter = 0;
        pageLoaderCounter = PageLoader.CounterPageLoader;
        pageLoaderCounter += 1
        ApiCategory((IsOk,response) =>{
            if(IsOk)
            {
                setCategories(response);
                pageLoaderCounter -= 1;
                PageLoader.setCounterPageLoader(pageLoaderCounter);
            }
            else{
                console.log(response);
            }
        });
    },[])
    const ProductFilterCategoryHandeler = () => 
    {
        ProductsContext.ProductFilterCategory(" ");        
    }
    
    return (
        <div className={style.categorymobilecontiner}>
            
                <div onClick={ProductFilterCategoryHandeler} className={ProductsContext.selectedCategory === " " ? style.sectionselected : style.section }>
                
                    <span><img src={icongategory} width="50px" height="50px"/></span>
                
                <div className={style.namecategory} >
                <span>همه محصولات</span>

                </div>
                </div>
        
            {
                categories.map(category => <CategoryMobile  key={category.id} ProductFilterCategory={ProductsContext.ProductFilterCategory} selectedCategoryUrl={ProductsContext.selectedCategory}  category={category} />)
                
            }
        </div>
    );
};

export default CategoriesMobile;