import React, { useEffect, useState } from 'react';
import UserpanelNavbar from '../UserpanelNavbar';
import style from './userorders.module.css';
import OrderImage from '../../../images/apple.jpg';
import { ApiPaidOrderDetail, ApiPaidOrders } from '../../../Api/ApiOrder';

const UserOrders = () => {
    const [userOrders,setUserOrders] = useState();
    const [orderDetail,setOrderDetail] = useState();
    const [activeorder,SetActiveorder] =useState(false)
    const [orderDetailMobile,setOrderDetailMobile] = useState({});

    // const activeorderhandler =()=>{
      
    // }

    useEffect(()=>{
    
        ApiPaidOrders(localStorage.getItem('userToken'),(IsOk,response1) => 
        {
            if(IsOk)
            {
                setUserOrders(response1);
                ApiPaidOrderDetail(localStorage.getItem('userToken'),response1[0].orderId,(IsOk,response2) => 
                {
                    if(IsOk)
                    {
                        setOrderDetail(response2);
                    }
                    else{
                       console.log(response2);
                    }
                });
            }
            else{
               console.log(response1);
            }
        });

    
        
    },[])


    const ChangeOrderDetailHandeler = (orderId) => {
        ApiPaidOrderDetail(localStorage.getItem('userToken'),orderId,(IsOk,response) => 
        {
            if(IsOk)
            {
                setOrderDetail(response);
              
            }
            else{
               console.log(response);
            }
            SetActiveorder(true)
        });
       
    }
  



    return (
        <div className={style.container}>
            <UserpanelNavbar/>
            {/* Desktop size */}
            <div className={style.ordersection}>
                <h4>سفارشات من</h4>
                    {
                        userOrders?.map(order=>{
                            return(
                                <div onClick={() => ChangeOrderDetailHandeler(order.orderId)} className={activeorder? style.orderactivecontainer : style.ordercardcontainer}>
                                    <div className={style.ordercardheader}>
                                        <span><strong>سفارش</strong><span>{order.orderId}</span></span>
                                        <span className={style.stateorder}>پرداخت شده</span>
                                    </div>
                                    <div className={style.ordercardbody}>
                                        <p><span>ثبت سفارش</span> <span>:</span> <span>{order.paymentDate}</span></p>
                                        <p><span>نحوه ارسال</span> <span>:</span> <span>{order.orderShippingMethods[0].shippingMethod.name}</span></p>
                                        <p><span>هزینه ارسال</span> <span>:</span> <span>{order.orderShippingMethods[0].shippingMethod.shippingMethodPrice}تومان</span></p>
                                        <p><span>هزینه</span> <span>:</span> <span>{order.orderPrice}تومان</span></p>
            
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
            <div className={style.orderdetial}>
                 <span><h4>جزییات سفارش - <span>{orderDetail?.orderId}</span></h4></span>

                 <div className={style.status}>
                    <div className={style.orderstatus}>
                        <p><strong>وضعیت سفارش  <span>:</span> </strong></p><span className={style.orderstatusstate}>در حال ارسال</span>
                    </div>
                    <div className={style.paymentstatus}>
                        <p><strong>نوع پرداخت  <span>:</span> </strong></p> <span className={style.paymentstate}>زرین پال</span>
                    </div>
                 </div>

                 <div className={style.aboutorder}>
                    <div className={style.aboutpayment}>
                        <p><span className={style.opacity}>هزینه</span> <span>{orderDetail?.orderPrice} تومان</span></p> 
                        <p><span className={style.opacity}>هزینه ارسال</span> <span>{orderDetail?.orderShippingMethods[0].shippingMethod.shippingMethodPrice} تومان</span></p> 
                        <p><span className={style.opacity}>تخفیف</span> <span>{orderDetail?.orderDiscountPrice} تومان</span></p> 
                        {/* <p><span className={style.opacity}>مالیات</span> <span>1000 تومان</span></p>  */}
                        <p><span className={style.total}>جمع کل</span> <span className={style.total}>{orderDetail?.orderPrice + orderDetail?.orderShippingMethods[0].shippingMethod.shippingMethodPrice - orderDetail?.orderDiscountPrice} تومان</span></p> 
                    </div>
                    <div className={style.aboutshippinaddress}>
                        <p><span><strong>ارسال به آدرس <span>:</span> </strong></span></p>
                        <p className={style.opacity}>{orderDetail?.orderAddress.addressText}</p>
                        <p><span><strong>نحوه ارسال<span>:</span> </strong></span></p>
                        <p className={style.opacity}>{orderDetail?.orderShippingMethods[0].shippingMethod.name}</p>

                    </div>
                    
                 </div>
                 <table className={style.ordertable}>
                     <tr className={style.ordertabletr}>
                          <td className={style.imagetitlecolspane}>مشخصات محصول</td>
                          <td>تعداد</td>
                          <td>قیمت</td>
                      </tr>
                           
                    {
                     
                        orderDetail?.orderDetail.map(Item => {
                            return(
                                <tr>
                                    <td className={style.imagetitlecolspane}><img alt='pic' src={OrderImage}  className={style.orderimageicon}/><span>{Item.product.title}</span></td>
                                    <td>{Item.count}</td>
                                    <td>{(Item.productPrice + Item.productDifferencPrice - Item.productDiscountPrice) * Item.count}</td>
                                </tr>
                            )
                        })
                    }
                        
                    </table>
            </div>
            
            {/* mobile size */}
   
   
        <div className={style.mobilecontainer}>
            {
                userOrders?.map((order,index) => 
                {
                    var OrderDetailMobile = orderDetailMobile;
                    ApiPaidOrderDetail(localStorage.getItem('userToken'),order.orderId,(IsOk,response) => 
                    {
                        if(IsOk)
                        {
                            OrderDetailMobile[index] = response;
                            setOrderDetailMobile(OrderDetailMobile);
                        }
                        else{
                           console.log(response);
                        }
                    });
                    return(
                        <div className={style.ordersectionmobile}>
                       
                        
                            <div className={ style.ordercardcontainermobile}>
                                <div className={style.ordercardheadermobile}>
                                    <span><strong>سفارش</strong><span>{order.orderId}</span></span>
                                    <span className={style.stateordermobile}>وضعیت سفارش </span>
                                </div>
                                <div className={style.ordercardbodymobile}>
                                    <p><span>ثبت سفارش</span> <span>:</span> <span>{order.paymentDate}</span></p>
                                    <p><span>نحوه ارسال</span> <span>:</span> <span>{order.orderShippingMethods[0].shippingMethod.name}</span></p>
                                    <p><span>هزینه ارسال</span> <span>:</span> <span>{order.orderShippingMethods[0].shippingMethod.shippingMethodPrice}تومان</span></p>
                                    <p><span>هزینه</span> <span>:</span> <span>{order.orderPrice}تومان</span></p>
        
                                </div>
                            </div> 
                            {/* llll */}
                            <div className={style.orderdetialmobile}>
                         <span><h4>جزییات سفارش - <span>{OrderDetailMobile[index]?.orderId}</span></h4></span>
        
                         <div className={style.statusmobile}>
                            <div className={style.orderstatusmobile}>
                                <p><strong>وضعیت سفارش  <span>:</span> </strong></p><span className={style.orderstatusstatemobile}>در حال ارسال</span>
                            </div>
                            <div className={style.paymentstatusmobile}>
                                <p><strong>نوع پرداخت  <span>:</span> </strong></p> <span className={style.paymentstatemobile}>زرین پال</span>
                            </div>
                         </div>
        
                         <div className={style.aboutordermobile}>
                            <div className={style.aboutpaymentmobile}>
                                <p><span className={style.opacitymobile}>هزینه</span> <span>{OrderDetailMobile[index]?.orderPrice} تومان</span></p> 
                                <p><span className={style.opacitymobile}>هزینه ارسال</span> <span>{OrderDetailMobile[index]?.orderShippingMethods[0].shippingMethod.shippingMethodPrice} تومان</span></p> 
                                <p><span className={style.opacitymobile}>تخفیف</span> <span>{OrderDetailMobile[index]?.orderDiscountPrice} تومان</span></p> 
                                
                                <p><span className={style.totalmobile}>جمع کل</span> <span className={style.totalmobile}>{OrderDetailMobile[index]?.orderPrice + OrderDetailMobile[index]?.orderShippingMethods[0].shippingMethod.shippingMethodPrice - OrderDetailMobile[index]?.orderDiscountPrice} تومان</span></p> 
                            </div>
                            <div className={style.aboutshippinaddressmobile}>
                                <p><span><strong>ارسال به آدرس <span>:</span> </strong></span></p>
                                <p className={style.opacitymobile}>{OrderDetailMobile[index]?.orderAddress.addressText}</p>
                                <p><span><strong>نحوه ارسال<span>:</span> </strong></span></p>
                                <p className={style.opacitymobile}>{OrderDetailMobile[index]?.orderShippingMethods[0].shippingMethod.name}</p>
        
                            </div>
                            
                         </div>
                         <table className={style.ordertablemobile}>
                                {/* <tr className={style.ordertabletrmobile}>
                                    <td className={style.imagetitlecolspanemobile}>مشخصات محصول</td>
                                    <td>تعداد</td>
                                    <td>قیمت</td>
                                </tr> */}
                                {
                                    OrderDetailMobile[index]?.orderDetail.map(Item => {
                                        return(
                                        <>
                                            
                                            <tr>
                                                <td className={style.ordertabletrmobile}>مشخصات محصول</td>
                                                <td className={style.ordertablemobileleftsection}><img alt='pic' src={OrderImage}  className={style.orderimageicon}/><span>{Item.product.title}</span></td>
                                            </tr>
                                            <tr>
                                                <td className={style.ordertabletrmobile}>تعداد</td>
                                                <td className={style.ordertablemobileleftsection}>{Item.count}</td>
                                            </tr>
                                            <tr>
                                                <td className={style.ordertabletrmobile}>قیمت</td>
                                                <td className={style.ordertablemobileleftsection}>{(Item.productPrice + Item.productDifferencPrice - Item.productDiscountPrice) * Item.count}</td>
                                            </tr>
                                         </>
                                            
                                        )
                                    })
                                }
                            </table>
                    </div>
                    </div>
                )
                })
            }
          


        </div>

            
           

        </div>
    );
};

export default UserOrders;
{/* <td>{Item.count}</td>
<td>{(Item.productPrice + Item.productDifferencPrice - Item.productDiscountPrice) * Item.count}</td> */}