import React from 'react';
import { faPenSquare, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShippingMethod from './ShippingMethod.js';
import styles from './checkout.module.css';
const ShippingMethodSeller = ({Index,shippingMethodSeller,OrderConfig}) => {
    return (
        <div className={styles.sendwith}>
        <div className={styles.titleformschekoutshipping}>
              <span className={styles.numberfield}>{Index + 2}</span>
            <p className={styles.titlefield}>نحوه ارسال {shippingMethodSeller.storeName}</p>
        </div>
        <div className={styles.addressdiv}>
        {
            shippingMethodSeller.shippingMethodDTOs.map((shippingMethod,Index) => <ShippingMethod key={Index} OrderConfig={OrderConfig} shippingMethod={shippingMethod} />)
        }
        </div>
  
    </div>
    );
};

export default ShippingMethodSeller;